import appSetting from '../../appSetting'
/**
 * 环境配置
 */
const env = appSetting.isDevelopment  ? 'dev' : 'prod'

/**
 * 不同环境的api
 */
const EnvConfig = {
    dev: {
        // baseApi: '/api',
        baseApi: 'http://community-mall.rsck.group/',
        // baseApi: 'https://community-mall.mengjingloulan.com/',
        mockApi: '/mock'
    },
    prod: {
        // baseApi: 'http://api.rsck.group/',//测试服务器地址
        baseApi: 'https://community-mall.mengjingloulan.com/', //线上服务器地址
        mockApi: '/mock'
    }
}
/**
 * api地址
 */
const apiUrl = {
    getSwiperList: 'card-pic-url',//卡片列表页面-推广图,
    getGoodsDetail: 'second-goods-info', //获取商品详情，后面这个接口要重新对接
    getAddPageSetting: 'goods-add',//服务数据添加页面配置
    getRebatePrice: 'get_rebate_price',//获取结算金额接口
    saveAddData: 'goods-save',//二手货卖家添加保存数据
    myService: 'goods-my-list',//二手货-社区服务卖家列表
    goodsList: 'goods-list',//二手货+社区服务列表(首页数据)
    getVillageList: 'village-list',//小区列表
    getEditPageSetting: 'goods-edit',//二手货+服务编辑数据页面
    saveUpdate: 'goods-edit-save',//二手货卖家编辑保存数据
    setOnOrOff: 'second-set_put',//更新商品或服务上下架,
    deleteGoods: 'goods-delete',//信息详情页-删除接口
    getConfirmOrderInfo: 'second-pre-order',//商品+服务预支付接口
    secondOrderInfo: 'second-order-info',//订单详情页面
    secondVerifyQrcode: 'second-verify-qrcode',//订单详情页面
    secondCancelReason: 'second-cancel-reason',//订单取消原因列表
    secondCancelApply: 'second-cancel-apply',//订单取消页面
    handleRefundApply: 'second-cancel-order',//提交退款信息
    createOrder: 'second-create-order',//下订单接口
    getContactMobile: 'second-mobile',//客服电话
    getCalendar: 'second-calendar',//日历-服务时间,
    getSellerOrder: 'second-seller-order',//卖方-我的订单列表
    verifyCode: 'second-verify_code',//订单核销功能
}
/**
 * api接口规则
 */
const rules = {}

export  default {
    mock: false,
    env,
    apiUrl,
    rules,
    ...EnvConfig[env],
}