import request from '@U/request.js'
import config from '@A/services/config.js'
import interceptRequest from '@A/services/intercept/request.js'
import interceptResponse from '@A/services/intercept/response.js'

const {
  apiUrl,
} = config

const service = new request(config, interceptRequest, interceptResponse)

const getSwiperList = (data) => {
  return service.get(apiUrl.getSwiperList, data)
}

const getGoodsDetail = (data) => {
  return service.get(apiUrl.getGoodsDetail, data)
}

const getAddPageSetting = (data) => {
  return service.get(apiUrl.getAddPageSetting, data)
}

const getRebatePrice = (data) => {
  return service.get(apiUrl.getRebatePrice, data)
}

const saveAddData = (data) => {
  return service.post(apiUrl.saveAddData, data)
}

const myService = (data) => {
  return service.get(apiUrl.myService, data)
}

const goodsList = (data) => {
  return service.get(apiUrl.goodsList, data)
}

const getVillageList = (data) => {
  return service.get(apiUrl.getVillageList, data)
}

const getEditPageSetting = (data) => {
  return service.get(apiUrl.getEditPageSetting, data)
}

const saveUpdate = (data) => {
  return service.post(apiUrl.saveUpdate, data)
}
const secondOrderInfo = (data) => {
  return service.get(apiUrl.secondOrderInfo, data)
}
const secondVerifyQrcode = (data) => {
  return service.get(apiUrl.secondVerifyQrcode, data)
}

const setOnOrOff = (data) => {
  return service.post(apiUrl.setOnOrOff, data)
}

const deleteGoods = (data) => {
  return service.get(apiUrl.deleteGoods, data)
}

const getConfirmOrderInfo = (data) => {
  return service.get(apiUrl.getConfirmOrderInfo, data)
}
const secondCancelReason=(data) => {
  return service.get(apiUrl.secondCancelReason, data)
}
const secondCancelApply=(data) => {
  return service.get(apiUrl.secondCancelApply, data)
}
const handleRefundApply=(data) => {
  return service.post(apiUrl.handleRefundApply, data)
}

const createOrder = (data) => {
  return service.post(apiUrl.createOrder, data)
}

const getContactMobile = (data) => {
  return service.get(apiUrl.getContactMobile, data)
}

const getCalendar = (data) => {
  return service.get(apiUrl.getCalendar,data)
}

const getSellerOrder = (data) => {
  return service.get(apiUrl.getSellerOrder,data)
}

const verifyCode = (data) => {
  return service.post(apiUrl.verifyCode,data)
}


export default {
  getSwiperList,
  getGoodsDetail,
  getAddPageSetting,
  getRebatePrice,
  saveAddData,
  myService,
  goodsList,
  getVillageList,
  getEditPageSetting,
  saveUpdate,
  secondOrderInfo,
  secondVerifyQrcode,
  setOnOrOff,
  deleteGoods,
  getConfirmOrderInfo,
  secondCancelReason,
  secondCancelApply,
  handleRefundApply,
  createOrder,
  getContactMobile,
  getCalendar,
  getSellerOrder,
  verifyCode
}