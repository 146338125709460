//商铺相关信息
const Stores = [
    {
        path: 'order_list',
        name: 'OrderList',
        component: () => import('@/pages/stores/order_list.vue')
    },
    {
        path: 'order_info',
        name: 'OrderInfo',
        component: () => import('@/pages/stores/orderInfo.vue')
    }
]
export default Stores