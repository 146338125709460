<template>
  <div v-if="show" ref="codeV1" @touchmove.prevent class="codeV1">
    <div class="codeV1_content">
      <div class="codeV1_content_body">
        <div class="codeV1_content_body_title">{{ title }}</div>
        <img
          class="codeV1_content_body_img"
          :class="['codeV1_content_body_img-size' + imgSize]"
          :src="codeUrl"
        />
        <div v-if="tip" class="codeV1_content_body_tip">{{ tip }}</div>
      </div>
      <button class="codeV1_button" @click="confirm">
        {{ confirmText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      title: "", // 标题
      codeUrl: "", //二维码链接地址
      tip: "",
      imgSize: "224",
      confirmText: "确定", // 确认文字
      success: () => {
        // 确认执行函数
        this.$emit("confirm");
      },
    };
  },
  methods: {
    confirm() {
      this.success();
      this.close();
    },
    close() {
      this.show = false;
      if (this.$refs.modal) {
        this.$refs.codeV1.remove(); // 从dom中移除当前组件
        this.$destroy(); //执行组件销毁
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(codeV1) {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  @include e(content) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 335px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include e(body) {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      @include e(title) {
        margin-left: 22px;
        margin-top: 20px;
        font-size: 16px;
      }
      @include e(img) {
        margin: 0 auto;
        margin-top: 20px;
        @include m(size224) {
          width: 224px;
          height: 224px;
        }
        @include m(size242) {
          width: 224px;
          height: 224px;
        }
      }
      @include e(tip) {
        width: 100%;
        margin-top: 20px;
        font-size: 20px;
        box-sizing: border-box;
        padding: 0 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        word-break: break-all;
      }
    }
  }
  @include e(button) {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 30px;
    outline: none;
    border: none;
    width: 285px;
    height: 44px;
    background: #ffece1;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f46a17;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      opacity: 0.5;
    }
  }
}
</style>
