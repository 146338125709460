import Cookies from 'js-cookie';
import $contract from '@A/contract/index.js';
import {
	updataStore
} from '@/store/common.js';
let lifeData = {};

try {
	// 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
	lifeData = Cookies.get('lifeData_contract_renew');
} catch (e) {

}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = [];
export default {
	namespaced: true,
	state: {
		//续签合同详情
		reNewInfo: {
			start_time: "2021年11月01", //新合同开始时间
			end_time: "2022年11月01", //新合同结束时间
			examine_text: "新添加草稿合同", //小程序弹窗提示文本
			first_party: "梦回楼兰", //甲方
			id: "60", //合同id
			second_party: "", //入驻合同签名
			second_appendix_party: "", //附加合同签名
			sign_time: "2021-11-01", //签合同的时间
			status: 0, //当前合同的状态
			store_id: 205, //店铺的id
			supplement: {
				discount: "合作折扣", //合作折扣
				remarks: "未尽事宜补充", //未尽事宜补充
				need_list: [], //需要的资料
				storeinfo: [], //店铺详情
				report: {
					tel: "182123456456", //受理电话
					email: "rsck123@163.com" //电子邮箱
				},
			},
		},
	},
	mutations: {
		updataStore(state, payload) {
			updataStore('contract_renew', saveStateKeys, state, payload);
		}
	},
	actions: {
		async getReNewInfo({
			state,
			commit,
			rootState,
		}) {
			try {
				let query = await $contract.renew.getInfo({
					re_sign: 1,
					user_id: rootState.common_platform.header['user-id'] || 0,
				});
				if (query.data.error == 0) {
					commit('updataStore',{name:'reNewInfo',value:query.data.data});
					return Promise.resolve(query);
				}
			} catch (error) {
				return Promise.reject(error);
			}

		},
	},
	getters: {}
}