<template>
  <div class="scrollview">
    <div class="scrollview_item scrollbar" :style="{ height: height + 'px' }">
      <v-refresh ref="refresh" @onRefresh="onRefresh">
        <v-reload @reload="onInfiniteLoad" :parentPullUpState="pullUpState">
          <slot v-if="showList">
            <emptyV1 v-if="loadType == ''"></emptyV1>
          </slot>
        </v-reload>
      </v-refresh>
    </div>
  </div>
</template>

<script>
import DropDownRefresh from "./DropDownRefresh";
import PullUpReload from "./PullUpReload";
import emptyV1 from "@T/community/empty/emptyV1";

export default {
  name: "scrollview",
  props: {
    height: 0,
  },
  data() {
    return {
      loadType: "", //refresh 当前正在下拉刷新 reload 当前正在上拉加载
      pullUpState: 0, // 子组件的pullUpState状态
      showList: false, //延时渲染
    };
  },
  components: {
    "v-refresh": DropDownRefresh,
    "v-reload": PullUpReload,
    emptyV1,
  },
  mounted() {
    // this.height = document.querySelector(".scrollview").clientHeight + "px";
    this.showList = true;
  },
  methods: {
    /**
     * 初始化下拉刷新
     */
    refreshDone() {
      this.loadType = "";
      this.$refs.refresh.refreshDone();
    },

    /**
     * 监听下拉刷新
     */
    onRefresh() {
      if (this.loadType) {
        return;
      }
      this.loadType = "refresh";
      this.pullUpState = 0;
      this.$emit("onRefresh", this.refreshDone);
    },
    /**
     * 加载结束 无法继续加载了
     */
    reloadDoneOver() {
      this.loadType = "";
      this.pullUpState = 2;
      this.$refs.refresh.refreshDone();
    },
    /**
     * 加载结束 还可以继续加载
     */
    reloadDoneBreak() {
      this.loadType = "";
      this.pullUpState = 0;
    },
    /**
     * 监听上拉加载
     */
    onInfiniteLoad() {
      if (this.loadType) {
        return;
      }
      this.loadType = "reload";
      this.pullUpState = 1;
      this.$emit("onReload", this.reloadDoneBreak, this.reloadDoneOver);
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(scrollview) {
  width: 100%;
  /*height: 100%;*/
  display: block;
  @include e(item) {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: scroll;
  }
}
</style>
