var jweixin = require('jweixin-module');
var jweixin2 = require('./jweixin');
import config from '@A/common/config.js';
import $api from '@A/common/index.js'
/**
 * 判断用户是否在微信小程序的环境中
 * @returns 
 */
export function isWechat() {
    //判断是否在微信浏览器环境下
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}
/**
 * 初始化微信jssdk
 * @returns 
 */
export function initConfig() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        let url = window.location.href
        $api.jsweixin.getConfig({
            url: url
        }).then((res) => {
            let result = JSON.parse(res);
            // 注入配置信息，同一个url仅需调用一次，对于变化url的SPA的web app可在每次url变化时进行调用
            jweixin.config({
                debug: false,
                appId: result.appId,
                timestamp: result.timestamp,
                nonceStr: result.nonceStr,
                signature: result.signature,
                jsApiList: config.jsApiList,
            });

            jweixin.ready(function (ready) {
                console.log('jssdk初始化成功', ready);
                resolve({
                    code: 1,
                    msg: 'jssdk初始化成功'
                });
            });

            jweixin.error(function (err) {
                console.log('jssdk初始化失败', err);
                reject({
                    code: 0,
                    msg: 'jssdk初始化失败'
                });
            });
        })
    })
}

/**
 * 获取用户地理位置
 * @returns 
 */
export function getLocation() {
    return new Promise((resolve, reject) => {
        jweixin.getLocation({
            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: function (res) {
                //   var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                //   var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                //   var speed = res.speed; // 速度，以米/每秒计
                //   var accuracy = res.accuracy; // 位置精度
                resolve(res)
            },
            fail: function (err) {
                reject(err);
            }
        });
    })

}
/**
 * 打开地图
 * @param {Number} latitud   纬度
 * @param {Number} longitude 经度
 * @param {String} name      位置名称
 * @param {String} address   地址详情说明
 * @param {Number} scale     地图缩放
 * @param {String} infoUrl 
 */
export function openLocation(latitude = 0, longitude = 0, name = '', address = '', scale = 1, infoUrl = '') {
    if (!latitude) {
        throw new Error('Latitude is a mandatory parameter');
    }
    if (!longitude) {
        throw new Error('Longitude is a mandatory parameter');
    }
    jweixin.openLocation({
        latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
        name: name, // 位置名
        address: address, // 地址详情说明
        scale: scale, // 地图缩放级别,整型值,范围从1~28。默认为最大
        infoUrl: infoUrl // 在查看位置界面底部显示的超链接,可点击跳转
    })
}

/**
 * 调起微信扫一扫功能
 * @returns 
 */
export function scanQRCode() {
    return new Promise(function (resolve, reject) {
        jweixin.scanQRCode({
            needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                // var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                resolve(res);
            },
            fail(err) {
                reject(err);
            }
        })
    })
}

/**
 * 调起微信支付
 * @param {
 *  timestamp // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
 * nonceStr   // 支付签名随机串，不长于 32 位
 * packages   // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
 * signType   // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
 * paySign    // 支付签名
 * } 
 * @returns 
 */
export function WXPay({
    timestamp = 0,
    nonceStr = '',
    packages = '',
    signType = '',
    paySign = '',
}) {
    return new Promise((resolve, reject) => {
        if (!timestamp) {
            throw new Error('timestamp is a mandatory parameter')
        }
        if (!nonceStr) {
            throw new Error('nonceStr is a mandatory parameter')
        }
        if (!packages) {
            throw new Error('packages is a mandatory parameter')
        }
        if (!signType) {
            throw new Error('signType is a mandatory parameter')
        }
        if (!paySign) {
            throw new Error('paySign is a mandatory parameter')
        }
        jweixin.choosejweixinPay({
            timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
            package: packages, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: paySign, // 支付签名
            success: function (res) {
                // 支付成功后的回调函数
                resolve(res);
            },
            fail(err) {
                reject(err);
            }
        });

    })
}

/**
 * 从本地相册选择一张图片 
 * @param {Number} count // 可以选择的图片数量 默认9
 * @param {Array} sizeType // 可以指定是原图还是压缩图，默认二者都有 'original','compressed'
 * @param {Array} sourceType // 可以指定来源是相册还是相机，默认二者都有 'album','camera'
 * @returns localIds     返回选定照片的本地ID列表
 */
export function chooseImage(count = 9, sizeType = ['original', 'compressed'], sourceType = ['album', 'camera']) {
    return new Promise((resolve, reject) => {
        jweixin.chooseImage({
            count: count,
            sizeType: sizeType,
            sourceType: sourceType,
            success: function (res) {
                var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
                resolve(localIds);
            },
            fail(err) {
                reject(err);
            }
        })
    })

}
/**
 * 上传图片到微信服务器
 * @param {String} localId //需要上传的图片的本地id 有chooseImage 获取
 * @returns serverId  返回图片的服务器端ID
 */
export function uploadImage(localId) {
    if (!localId) {
        throw new Error('图片id为必填项')
    }
    return new Promise((resolve, reject) => {
        jweixin.uploadImage({
            localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
                var serverId = res.serverId; // 返回图片的服务器端ID
                resolve(serverId);
            },
            fail(err) {
                reject(err);
            }
        })
    })
}

/**
 * 下载微信服务器图片到本地
 * @param {String} serverId  需要下载的图片的服务器端ID，由uploadImage接口获得
 * @returns localId 返回图片下载后的本地ID
 */
export function downloadImage(serverId) {
    if (!serverId) {
        throw new Error('ServerId is a mandatory parameter')
    }
    return new Promise((resolve, reject) => {
        jweixin.downloadImage({
            serverId: serverId, // 需要下载的图片的服务器端ID，由uploadImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function (res) {
                var localId = res.localId; // 返回图片下载后的本地ID
                resolve(localId)
            },
            fail(err) {
                reject(err)
            }
        });
    })
}
/**
 * 获取微信图片的本地信息
 * @param {String} localId 图片的localID
 * @returns localData localData是图片的base64数据，可以用img标签显示
 */
export function getLocalImgData(localId) {
    return new Promise((resolve, reject) => {
        jweixin.getLocalImgData({
            localId: localId, // 图片的localID
            success: function (res) {
                var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                resolve(localData)
            },
            fail(err) {
                reject(err)
            }
        });
    })
}

/**
 * 预览图片
 * @param {String} current   // 当前显示图片的http链接
 * @param {Array} urls      // 需要预览的图片http链接列表
 * @returns 
 */
export function previewImage(current = '', urls = []) {
    if (!urls.length) {
        throw new Error('图片长度不能为空');
    }
    if (!current) {
        current = urls[0];
    }
    jweixin.previewImage({
        current, // 当前显示图片的http链接
        urls // 需要预览的图片http链接列表
    });
}

/**
 * 网页端跳转微信小程序页面路径
 * @param {
 * type  跳转类型同微信小程序
 * url   跳转路径同微信小程序页面路径
 * }  
 */
export function wxRoute({
    type = 'navigateTo',
    url = '',
    delta = 1,
}) {
    console.log(url)
    console.log(type)
    console.log('jweixin2', jweixin2);
    console.log('jweixin', jweixin);
    switch (type) {
        case 'navigateBack': {
            //返回到小程序上一级
            jweixin2.miniProgram.navigateBack({
                delta: delta
            });
            break;
        }
        case 'switchTab': {
            //跳转到小程序tabbar页面
            jweixin2.miniProgram.switchTab({
                url: url
            });
            break;
        }
        case 'redirectTo': {
            //关闭当前页面，跳转到应用内的某个页面。但是不允许跳转到 tabbar 页面
            jweixin2.miniProgram.redirectTo({
                url: url
            });
            break;
        }
        case 'reLaunch': {
            //关闭当前页面跳转到小程序页面
            jweixin2.miniProgram.reLaunch({
                url: url
            });
            break;
        }
        case 'navigateTo': {
            //保留当前页面，跳转到应用内的某个页面。但是不能跳到 tabbar 页面
            jweixin2.miniProgram.navigateTo({
                url: url
            });
            break;
        }
        default: {
            break;
        }
    }
}

/**
 * 获取当前环境
 * @returns 
 */
export function getEnv() {
    return jweixin2.miniProgram.getEnv();
}
/**
 * 向小程序发送消息，会在特定时机（小程序后退、组件销毁、分享）触发组件的message事件
 * 该事件要在组件销毁时候调用
 * msg
 */
export function postMessage(msg) {
    jweixin2.miniProgram.postMessage({
        data: msg
    });
}