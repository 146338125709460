import appSetting from '../../appSetting'
/**
 * 环境配置
 */
const env = appSetting.isDevelopment  ? 'dev' : 'prod'
/**
 * 不同环境的api
 */
const EnvConfig = {
    dev: {
        // baseApi: '/api',
        baseApi: 'http://community-mall.rsck.group/',
        // baseApi: 'https://community-mall.mengjingloulan.com/',
        mockApi: '/mock'
    },
    prod: {
        // baseApi: 'http://api.rsck.group/',//测试服务器地址
        baseApi: 'https://community-mall.mengjingloulan.com/', //线上服务器地址
        mockApi: '/mock'
    }
}
/**
 * api地址
 */
const apiUrl = {
    getTurnTableSetting: 'turn-table-setting',//获取大转盘设置
    getTurnTableGiftRecord: 'turn-table-gift-record',//中奖记录
    getTurnTableGift: 'turn-table-gift',//抽奖
    getGiftLog: 'turn-table/gift-log',//中奖记录

}
/**
 * api接口规则
 */
const rules = {}

export default {
    mock: false,
    env,
    apiUrl,
    rules,
    ...EnvConfig[env],
}