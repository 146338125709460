import {
    Random
} from 'mockjs';
const Mock = require('mockjs');
/**
 * 合同相关的
 */
const community = {
    /**
     * 获取首页轮播图列表
     * @type get
     * @returns 
     */
    flash: {
        type: "get",
        func: (res) => {
            console.log('轮播图', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|10": [{
                        url: "", //跳转地址
                        open_type: "no_jump", //类型
                        img_path: Random.image('345x173', '#50B347', '#FFF', 'swiper'),
                    }]
                }).data
            }
        }
    },
    /**
     * 获取首页menu列表
     * @type get
     * @returns 
     */
    catalog: {
        type: "get",
        func: (res) => {
            console.log('获取首页menu列表', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|5-10": [{
                        catalog_name: '@ctitle(4)', //分类名称
                        cover: Random.image('43x43', '#50B347', '#FFF', 'menu'), //分类图片
                        url: 'category', //分类地址
                    }]
                }).data
            }
        }
    },
    /**
     * 获取首页热门商品列表
     * @type get
     * @returns 
     */
    'hot-goods': {
        type: "get",
        func: (res) => {
            console.log('获取首页热门商品列表', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|1-10": [{
                        "id|+1": 1, //商品id
                        "cover": "https://tse1-mm.cn.bing.net/th/id/OIP-C.OF59vsDmwxPP1tw7b_8clQHaE8?pid=ImgDet&rs=1", //封面
                        "title": "@ctitle()", //商品标题
                        "product_price|1-100.1-2": 1, //原价
                        "market_price|1-100.1-2": 0, //售价
                    }]
                }).data
            }
        }
    },
    /**
     * 获取热门搜索商品词汇
     * @type get
     * @returns 
     */
    hotSearch: {
        type: "get",
        func: (res) => {
            console.log('makesure.接收到的参数', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|1-10": [{
                        name: "@ctitle()"
                    }]
                }).data
            }
        }
    },
    /**
     * 搜索商品
     * @type get
     * @returns 
     */
    'search-goods': {
        type: "get",
        func: (res) => {
            console.log('搜索商品', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|1-10": [{
                        "id|+1": 1, //商品id
                        "cover": "https://tse1-mm.cn.bing.net/th/id/OIP-C.OF59vsDmwxPP1tw7b_8clQHaE8?pid=ImgDet&rs=1", //封面
                        "title": "@ctitle()", //商品标题
                        "product_price|1-100.1-2": 1, //原价
                        "market_price|1-100.1-2": 0, //售价
                    }]
                }).data
            }
        }
    },
    /**
     * 获取分类类别列表
     * @type get
     * @returns 
     */
    'catalog-list': {
        type: "get",
        func: (res) => {
            console.log('获取分类类别列表', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|1-10": [{
                        "id|+1": 1, //商品id
                        "cover": "https://tse1-mm.cn.bing.net/th/id/OIP-C.OF59vsDmwxPP1tw7b_8clQHaE8?pid=ImgDet&rs=1", //封面
                        "catalog_name": "@ctitle()", //商品标题
                        "url": '', //要跳转到的地方
                    }]
                }).data
            }
        }
    },
    /**
     * 根据分类搜索商品
     * @type get
     * @returns 
     */
    'catelog-goods-list': {
        type: "get",
        func: (res) => {
            console.log('catelog-goods-list', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|1-10": [{
                        "id|+1": 1, //商品id
                        "catalog_id|+1": 1, //分类id
                        "cover": "https://tse1-mm.cn.bing.net/th/id/OIP-C.OF59vsDmwxPP1tw7b_8clQHaE8?pid=ImgDet&rs=1", //封面
                        "title": "@ctitle()", //商品标题
                        "product_price|1-100.1-2": 1, //原价
                        "market_price|1-100.1-2": 0, //售价
                        "mail_type": '@integer(1, 2)', //1是快递 2是自提
                    }]
                }).data
            }
        }
    },
    /**
     * 根据商品id查询商品详情
     * @type get
     * @returns 
     */
    'goods-info': {
        type: "get",
        func: (res) => {
            console.log('goods-info', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    "data|1-10": [{
                        "id|+1": 1, //商品id
                        "catalog_id|+1": 1, //分类id
                        "cover": Random.image('43x43', '#50B347', '#FFF', '封面'), //封面
                        "cover_images|1-5": ["https://tse1-mm.cn.bing.net/th/id/OIP-C.OF59vsDmwxPP1tw7b_8clQHaE8?pid=ImgDet&rs=1"], //商品图片
                        "video_path": "https://storage.jd.com/about/big-final.mp4?Expires=3730193075&AccessKey=3LoYX1dQWa6ZXzQl&Signature=ViMFjz%2BOkBxS%2FY1rjtUVqbopbJI%3D", //视频地址
                        "title": "@ctitle()", //商品名称
                        "product_price|1-100.1-2": 1, //原价
                        "market_price|1-100.1-2": 0, //售价
                        "sales_number": '@integer(1, 10)', //售卖数量
                        "deduct": '@integer(0, 10)', //人生券抵扣
                        "store_id|+1": 1, //商户id
                        "is_follow|1": true, //是否收藏关注
                        "favorite_id": '@integer(0, 10)', //关注id
                        "share_title": "@ctitle()", //分享标题
                        "share_img": "@ctitle()", //分享图片
                        "stock_number": "@integer(0，100)", //商品库存
                        "content": "<p>订单</p>", //商品详情 富文本内容
                        "is_verify|1": true, //是否是核销商品

                    }]
                }).data
            }
        }
    },
    /**
     * 获取购物车列表
     * @type get
     * @returns 
     */
    'cart-list': {
        type: "get",
        func: (res) => {
            console.log('goods-info', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({
                    // 自提点商品列表
                    'site_points_goods|1-10': [{
                        'cart_id|+1': 1, //购物车id
                        'goods_id|+1': 1, //商品id
                        'title': '@ctitle()', //标题
                        'cover': Random.image('43x43', '#50B347', '#FFF', '封面'),
                        'number': '@integer(1,10)', //购买数量
                        'deduct': '@integer(1,10)', //每个商品可以抵扣的数量
                        'product_price|1-100.1-2': 1, //原价
                        'market_price|1-100.1-2': 1, //售价
                        'stock_number': '@integer(10,100)', //库存
                        'spec_ids': '', //规格id
                        'spec_ids_arr': [], //规格对照表
                    }],
                    // 配送商品列表
                    'express_goods|1-10': [{
                        'cart_id|+1': 1, //购物车id
                        'goods_id|+1': 1, //商品id
                        'title': '@ctitle()', //标题
                        'cover': Random.image('43x43', '#50B347', '#FFF', '封面'),
                        'number': '@integer(1,10)', //购买数量
                        'deduct': '@integer(1,10)', //每个商品可以抵扣的数量
                        'product_price|1-100.1-2': 1, //原价
                        'market_price|1-100.1-2': 1, //售价
                        'stock_number':  '@integer(10,100)', //库存
                        'spec_ids': '', //规格id
                        'spec_ids_arr': [], //规格对照表
                    }],
                    //自提点优惠的信息
                    'site_points_info': {
                        'save_money|1-100.1-2': 1, //节省的金额
                        'use_deduct|1-100.1-2': 1, //消耗的人生券
                        'total_price|1-100.1-2': 1, //总共需要支付的价钱
                        'total_product_price|1-100.1-2|1-100.1-2': 1, //总商品价格
                    },
                    //快递优惠的信息
                    'express_info': {
                        'save_money|1-100.1-2': 1, //节省的金额
                        'save_deduct|1-100.1-2': 1, //消耗的人生券
                        'total_price|1-100.1-2': 1, //总计需要支付的价格
                        'total_product_price|1-100.1-2': 1, //总商品价格
                        'total_shipping_fee|1-100.1-2': 1, //总运费价格
                    },
                })
            }
        }
    },
}
export default community;