<template>
  <div v-if="show" ref="previewImg" @click.prevent="" class="previewImg">
    <div class="previewImg_content">
      <div class="previewImg_content_body">
        <img :src="imgUrl" />
      </div>
      <div class="previewImg_foot">
        <button
          v-if="showCancel"
          class="previewImg_button"
          @click.stop="cancel"
        >
          {{ cancelText }}
        </button>
        <button
          v-if="showConfirm"
          class="previewImg_button"
          @click.stop="confirm"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      imgUrl: "",
      cancelText: "删除", //取消按钮
      showCancel: true, //显示取消
      confirmText: "关闭", // 确定按钮
      showConfirm: true, //显示确认
      success: () => {
        // 确认执行函数
        // this.$emit("confirm");
      },
      fail: () => {
        // this.$emit("cancel");
      },
    };
  },
  methods: {
    confirm() {
      console.log("success");
      this.success();
      this.close();
    },
    cancel() {
      this.fail();
      this.close();
    },
    close() {
      this.show = false;
      if (this.$refs.modal) {
        this.$refs.previewImg.remove(); // 从dom中移除当前组件
        this.$destroy(); //执行组件销毁
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(previewImg) {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 1);
  @include e(content) {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include e(body) {
      background-color: white;
      border-radius: 10px;
      width: 100%;
      max-height: 500px;
      overflow: hidden;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      @include e(img) {
        width: 100%;
      }
    }
  }
  @include e(foot) {
    margin-top: 50px;
    width: 100vw;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  @include e(button) {
    margin: 0 auto;
    margin-bottom: 15px;
    outline: none;
    border: none;
    width: 40%;
    height: 44px;
    background: #ffece1;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f46a17;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      opacity: 0.5;
    }
  }
}
</style>
