import appSetting from '../../appSetting'
/**
 * 环境配置
 */
const env = appSetting.isDevelopment  ? 'dev' : 'prod'
/**
 * 不同环境的api
 */
const EnvConfig = {
  dev: {
    // baseApi: '/api',
    baseApi: 'http://community-mall.rsck.group/',
    // baseApi: 'https://community-mall.mengjingloulan.com/',
    mockApi: '/mock'
  },
  prod: {
    // baseApi: 'http://api.rsck.group/',//测试服务器地址
    // baseApi: 'http://community-mall.rsck.group/',
    baseApi: 'https://community-mall.mengjingloulan.com/', //线上服务器地址
    mockApi: '/mock'
  }
}
/**
 * api地址
 */
const apiUrl = {
  //合同相关接口
  getCouponCardCatalog: 'gift-card-catalog',//我的卡片-分类
  getCouponCardList: 'gift-card-list',//我的卡片-分类列表,
  getCouponCardInfo: 'gift-card-info',//礼品卡详情
  doSendCouponCard: 'gift-card-receive',//赠送礼品卡
  getCouponOrderInfo: 'gift-card-can-receive',//卡包领取页面
  getCouponCardUseView: 'gift-card-goods-pay',//卡片领取支付页面,
  createCouponGetOrder: 'card_pay_money',//领取物品-支付快递费页面
  getGoodsInfo: 'gift-card-goods-info',//商品详情页
  getCouponCardCanBuyList: 'card-list',//可以购买的礼品卡列表
  couponCardInfo: 'gift-card-info',//可以购买的礼品卡详情
  couponCardBuyConfirm: 'gift-card-pre-pay',//购买预览接口
  couponCardBuy: 'gift-card-pay',//礼品卡订单支付功能
  getSwiperList:'card-pic-url',//卡片列表页面-推广图
  getRefundInfo:'card_apply_card_info',//卡片退款结果页面
  getCardUseLog:'card_receive_log',//卡片领取记录
  getOrderExpressInfo:'gift-goods-express',//领取记录快递信息
  getRefundReasonList:'card_reasons_list',//卡片退款原因列表
  handleRefundApply:'card_apply_refund',//卡片退款功能
  gitRule:'gift-rule',//总规则
  cardRecommendList:'card-recommend-list',//推荐的卡
}
/**
 * api接口规则
 */
const rules = {}

export  default {
  mock: false,
  env,
  apiUrl,
  rules,
  ...EnvConfig[env],
}