//礼品卡路由
const couponCard = [
  {
    path: 'lists',
    name: 'CouponCardLists',
    component: () => import('@/pages/coupon_card/lists.vue')
  },
  {
    path: 'home',
    name: 'CouponCardHome',
    component: () => import('@/pages/coupon_card/home.vue')
  },
  {
    path: 'detail',
    name: 'CouponCardDetails',
    component: () => import('@/pages/coupon_card/card-details.vue')
  },
  {
    path: 'refund',
    name: 'CouponCardRefund',
    component: () => import('@/pages/coupon_card/refund.vue')
  },
  {
    path: 'get',
    name: 'CouponCardGet',
    component: () => import('@/pages/coupon_card/card-get.vue')
  },
  {
    path: 'get-log',
    name: 'CouponCardGet',
    component: () => import('@/pages/coupon_card/card-get-log.vue')
  },
  {
    path: 'success-page',
    name: 'CouponCardSendSuccess',
    component: () => import('@/pages/coupon_card/success-page.vue')
  },
  {
    path: 'express-info',
    name: 'CouponCardSendSuccess',
    component: () => import('@/pages/coupon_card/card-express-info.vue')
  },
  {
    path: 'scan-result',
    name: 'CouponCardScanResult',
    component: () => import('@/pages/coupon_card/scan-result.vue')
  },
  {
    path: 'goods-detail',
    name: 'CouponCardGoodsDetail',
    component: () => import('@/pages/coupon_card/goods-detail.vue')
  },
  {
    path: 'card-buy',
    name: 'CouponCardBuy',
    component: () => import('@/pages/coupon_card/card-buy.vue')
  },
  {
    path: 'card-buy-confirm',
    name: 'CouponCardBuyConfirm',
    component: () => import('@/pages/coupon_card/card-buy-confirm.vue')
  },
]
export default couponCard