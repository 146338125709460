import Cookies from 'js-cookie';
import $community from '@A/community/index.js';
import {
    updataStore
} from '@/store/common.js';
let lifeData = {};

try {
    // 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
    lifeData = Cookies.get('lifeData_community_cart');
    lifeData = JSON.parse(lifeData);
} catch (e) {
    //
}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = [];
export default {
    namespaced: true,
    state: {
        //自提商品列表
        site_points_goods: [],
        //快递商品列表
        express_goods: [],
        //自提点优惠的信息
        site_points_info: {
            save_money: 0, //节省的金额
            use_deduct: 0, //消耗的人生券
            total_price: 0, //总共需要支付的价钱
            total_product_price: 0, //总商品价格
        },
        //快递优惠的信息
        express_info: {
            save_money: 0, //节省的金额
            save_deduct: 0, //消耗的人生券
            total_price: 0, //总计需要支付的价格
            total_product_price: 0, //总商品价格
            total_shipping_fee: 0, //总运费价格
        },
        //选中的自提商品
        site_points_checked: [],
        //选中的快递商品
        express_checked: []
    },
    mutations: {
        updataStore(state, payload) {
            updataStore('community_cart', saveStateKeys, state, payload);
        }
    },
    getters: {
        hasGoodsForGoodsId() {},
        hasGoodsForCartId() {},
        hasGoods() {},
    },
    actions: {
        /**
         * 将商品添加到购物车
         * @param {*} param0 
         * @param {goods_id,spec_ids,number} param1 //商品id 规格（默认是数组） 数量 
         */
        async addCart({
            state,
            commit,
        }, {
            goods_id,
            spec_ids, //规格ID，多个规格ID，按照值从小到大使用','连接
            number,
        }) {
            try {
                await $community.cart.addGoods({
                    goods_id,
                    spec_ids,
                    number
                });
            } catch (error) {
                //
            }
        },
        /**
         * 从购物车中删除
         * @param {*} param0 
         * @param {cart_ids,type}  购物车id  数组形式 //type 1自提 2快递
         */
        async delCart({
            state,
            commit,
            dispatch,
        }, {
            type,
            cart_ids
        }) {
            if (!type) {
                throw new Error("type值是必填项 1是自提 2是快递")
            }
            if (!cart_ids) {
                throw new Error("cart_id 是必要参数")
            }
            let typeName = "site_points_checked";
            if (type == 1) {
                typeName = "site_points_checked"
            } else {
                typeName = "express_checked"
            }
            let oldList = JSON.parse(JSON.stringify(state[typeName]));
            console.log(oldList,'oldList')
            let newList = oldList.map((val) => {
                if (cart_ids.indexOf(val.cart_id) == -1) {
                    return val;
                }
            })

           try {
                let del_cart_ids=cart_ids.join(',')
                await $community.cart.delGoods({cart_ids:del_cart_ids});
                commit('updataStore', {
                      name: typeName,
                      value: []
                 })
               await dispatch('initCart');

            } catch (error) {
                //
            }
        },
        /**
         * 更新购物车数量
         * @param {*} param0 
         * @param {type,cart_id,number} param1 //type 1自提 2快递 //cart_id 购物车id number数量
         */
        async updataCart({
            state,
            commit,
            dispatch
        }, {
            type, //类型 1自提 2快递 
            cart_id,
            number,
            spec_ids, //规格ID，多个规格ID，按照值从小到大使用','连接
        }) {
            if (!type) {
                throw new Error("type值是必填项 1是自提 2是快递")
            }
            if (!cart_id) {
                throw new Error("cart_id 是必要参数")
            }
            let typeName = "site_points_checked";
            if (type == 1) {
                typeName = "site_points_checked"
            } else {
                typeName = "express_checked"
            }
            let list = JSON.parse(JSON.stringify(state[typeName]));
            if (number > 0) {
                try {
                    await $community.cart.updataGoods({
                        cart_id: cart_id || 0,
                        number: number || 0,
                        spec_ids: spec_ids || ''
                    });
                    await dispatch('initCart');
                    // list = list.map((val) => {
                    //     if (val.cart_id == cart_id) {
                    //         val.number = number || 0;
                    //         val.spec_ids = spec_ids || '';
                    //     }
                    //     return val;
                    // })
                    // commit('updataStore', {
                    //     name: typeName,
                    //     value: list
                    // })
                } catch (error) {
                    //
                }
            } else {
                try {
                    await $community.cart.delGoods({
                        cart_ids: [cart_id]
                    });
                    let index = list.map((val, idx) => {
                        return idx
                    })[0];
                    commit('updataStore', {
                        name: typeName,
                        value: list.splice(index, 1)
                    })
                } catch (error) {
                    //
                }
            }

        },
        //初始化购物车
        async initCart({
            state,
            commit,
        }, payload) {
            try {
                let query = await $community.cart.getList();
                commit('updataStore', {
                    name: 'site_points_goods',
                    value: query.data.site_points_goods
                });
                commit('updataStore', {
                    name: 'express_goods',
                    value: query.data.express_goods
                });
                commit('updataStore', {
                    name: 'site_points_info',
                    value: query.data.site_points_info
                });
                commit('updataStore', {
                    name: 'express_info',
                    value: query.data.express_info
                });
            } catch (error) {
                //
            }
        },
        /**
         * 单选或者取消单选
         * @param {*} param0 
         * @param {type,cardId} param1 type 类型 1自提 2快递 cardId 购物车id 
         */
        changeChecked({
            state,
            commit
        }, {
            type,
            cardId
        }) {
            if (!type) {
                throw new Error("type值是必填项 1是自提 2是快递")
            }
            if (!cardId) {
                throw new Error("carId是必填项")
            }
            let typeName = "site_points_checked";
            let checkeds = -1;
            if (type == 1) {
                typeName = "site_points_checked"
            } else {
                typeName = "express_checked"
            }
            let list = JSON.parse(JSON.stringify(state[typeName]));
            checkeds = list.indexOf(cardId);
            if (checkeds >= 0) {
                list.splice(checkeds, 1)
            } else {
                list.push(cardId);
            }
            commit('updataStore', {
                name: typeName,
                value: list
            });
        },
        /**
         * 全选或反选
         * @param {*} param0 
         * @param {*} type 1 自提 2快递
         */
        changeAllChecked({
            state,
            commit
        }, type) {
            if (type > 0) {
                let typeName = "site_points_checked";
                let checkeds = [];
                if (type == 1) {
                    typeName = "site_points_checked"
                } else {
                    typeName = "express_checked"
                }
                checkeds = state[typeName];
                if (checkeds.length > 0) {
                    commit('updataStore', {
                        name: typeName,
                        value: []
                    });
                } else {
                    let list = []
                    if (type == 1) {
                        list = state.site_points_goods.map((val) => val.cart_id);
                    } else {
                        list = state.express_goods.map((val) => val.cart_id);
                    }
                    commit('updataStore', {
                        name: typeName,
                        value: list
                    });
                }

            } else {
                throw new Error("type值是必填项 1是自提 2是快递")
            }

        }
    },
    getters: {}
}