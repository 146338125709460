/***
 * 单个事件 指针对原生事件生效
 * 防抖 单位时间只触发最后一次
 *  @param {?String|"click"} event - 事件类型 例："click"
 *  @param {Function} fn - 执行事件
 *  @param {?Number|300} time - 间隔时间
 *  @param {Array} binding.value - [fn,event,time]
 *  例：<button v-debounce="[`click`,func,300]">防抖</button>
 *  也可简写成：<button v-debounce="[`click`,func,300]">防抖</button>
 */
export default (Vue) => {
    Vue.directive('debounce', {
        inserted: function (el, binding) {
            let [event = "click", fn, time = 600] = binding.value
            let timer = null;
            el.addEventListener(event, () => {
                timer && clearTimeout(timer)
                timer = setTimeout(() => fn(), time)
            })
        }
    })
}

// /***
//  * 多个事件
//  * 防抖 单位时间只触发最后一次
//  *  @param {?String|"click"} event - 事件类型 例："click"
//  *  @param {Function} fn - 执行事件
//  *  @param {?Number|300} time - 间隔时间
//  *  @param {Array} binding.value - [{fn,event,time}]
//  *  例：<button v-debounce="[`click`,func,300]">防抖</button>
//  *  也可简写成：<button v-debounce="[`click`,func,300]">防抖</button>
//  */
// const debounces = (Vue) => {
//     Vue.directive('debounces', {
//         inserted: function (el, binding, vnode) {
//             let timer = {};
//             let values = binding.value;
//             console.log('values', values);
//             values.forEach((val) => {
//                 let {
//                     event = "click", func = () => {}, time = 500
//                 } = val;
//                 el.addEventListener(event, () => {
//                     console.log('自定义制冷', event);
//                     timer[val.event] && clearTimeout(timer)
//                     timer[val.event] = setTimeout(() => func(), time)
//                 })
//             })

//         }
//     })
// }
// module.exports = {
//     debounce,
//     debounces
// }