import Cookies from 'js-cookie';
import {
	updataStore
} from '@/store/common.js';
let lifeData = {};

try {
	// 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
	lifeData = Cookies.get('lifeData_common_user');
} catch (e) {

}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = [''];
export default {
	namespaced: true,
	state: {
		userInfo: null,
	},
	mutations: {
		updataStore(state, payload) {
			updataStore('common_user', saveStateKeys, state, payload);
		}
	},
	actions: {
		async getUserInfo({
			state,
			commit,
			dispatch,
			rootState,
		}) {
			console.log('getUserInfo');
			console.log('token', rootState.vuex_token);
			if (info.code == 1) {
				commit('updataStore', {
					name: 'userInfo',
					value: info.data
				});
				dispatch('tabbar/initTabbar', null, {
					root: true
				});
			}

		},
		async updataUserInfo({
			state,
			dispatch,
			commit,
			rootState,
		}, params) {
			console.log('updataUserInfo', params, );
			await dispatch('getUserInfo');
		}
	},
	getters: {}
}