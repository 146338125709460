import axios from 'axios';
// axios 请求头
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers['token'] = localStorage.getItem('token') || '';
axios.defaults.headers.get['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/**
 * 二次封装一个axios，将其封装成为一个class类
 */
export default class request {
    #service = null;
    #config = null; //axios配置参数我也不知道有多少个
    #InterceptRequest = null;
    #InterceptResponse = null;
    constructor(
        config = {
            baseApi: '',
            timeout: 8 * 1000
        },
        intercepretRequest = null,
        interceptresponse = null
    ) {
        this.#config = config;
        this.#InterceptRequest = intercepretRequest;
        this.#InterceptResponse = interceptresponse;
        this.#service = axios.create({
            baseURL: config.baseApi,
            timeout: config.timeout,
        });


        // 请求拦截
        this.#service.interceptors.request.use(
            // eslint-disable-next-line no-shadow
            async (reqConfig) => {
                    // 在发送请求之前做些什么
                    // 可在这里做一些数据的校验。
                    // session的校验等。
                    if (this.#InterceptRequest !== null) {
                        try {
                            return await this.#InterceptRequest(reqConfig);
                        } catch (error) {
                            throw new Error(error);
                        }
                    }
                },
                (error) => {
                    // 对请求错误做些什么
                    Promise.reject(error);
                }
        );

        //响应拦截
        this.#service.interceptors.response.use(async (res) => {
            if (res.status === 200) {
                if (this.#InterceptResponse != null) {
                    try {
                        let query = await this.#InterceptResponse(res);
                        return Promise.resolve(query);

                    } catch (error) {
                        return Promise.reject(error);
                    }
                } else {
                    Promise.resolve(res);
                }
            }
        }, (err) => Promise.reject(err));

        /**
         * 这里遍历生成了get post put delete patch 方法
         * @param {String} url 'url链接地址'
         * @param {any} data '要传递的参数可以是任意的类型'
         * @param {...} options'多个参数'
         */
        ['get', 'post', 'put', 'delete', 'patch'].forEach((item) => {
            this[item] = (url, data, options) => this.#request({
                method: item,
                url,
                data,
                ...options
            })
        })
    }

    /**
     * 请求核心
     * @param {*} options 配置列表 
     */
    #request(options) {
        options.method = options.method || 'get';
        if (options.method.toLowerCase() === 'get') {
            options.params = options.data;
        }
        if (this.#config.env == 'prod') {
            this.#service.defaults.baseURL = this.#config.baseApi;
        } else {
            this.#service.defaults.baseURL = this.#config.mock ? this.#config.mockApi : this.#config.baseApi;
            if (options?.mock == true) {
                this.#service.defaults.baseURL = this.#config.mockApi
            } else if (options?.mock == false) {
                this.#service.defaults.baseURL = this.#config.baseApi

            }
        }
        if (options.apiType) {
            //这里可以有其他环境url
            this.#service.defaults.baseURL = this.#config[options.apiType];
        }

        return this.#service(options);
    }
}