import request from '@U/request.js'
import config from '@A/community/config.js'
import interceptRequest from '@A/community/intercept/request.js'
import interceptResponse from '@A/community/intercept/response.js'

const {
  apiUrl,
} = config

const service = new request(config, interceptRequest, interceptResponse)

//社区
const home = {
  //获取轮播图
  getSwiperList (data) {
    return service.get(apiUrl.home.getSwiperList, data, {
      mock: false
    })
  },
  //获取分类列表
  getMenuList (data) {
    return service.get(apiUrl.home.getMenuList, data, {
      mock: false
    })
  },
  //获取社区商城商品分类
  getGoodsCatalog (data) {
    return service.get(apiUrl.home.getGoodsCatalog, data, {
      mock: false
    })
  },

  // 获取商品筛选标签
  getGoodsLabelList (data) {
    return service.get(apiUrl.home.goodsLabels, data, {
      mock: false
    })
  },

  //获取热门商品
  getHotGoodsList (data) {
    return service.get(apiUrl.home.getHotGoodsList, data, {
      mock: false
    })
  },
  //今日团购
  groupBuyingToday (data) {
    return service.get(apiUrl.home.groupBuyingToday, data, {
      mock: false
    })
  },

  //近期团购
  groupBuyingCurrent (data) {
    return service.get(apiUrl.home.groupBuyingCurrent, data, {
      mock: false
    })
  },
}
//搜索
const search = {
  //获取热门搜索词
  getHotSearch (data) {
    return service.get(apiUrl.search.getHotSearch, data, {
      mock: false
    })
  },
  //通过关键词搜索商品
  search (data) {
    return service.get(apiUrl.search.search, data)
  }
}
//分类
const category = {
  getTypeList (data) {
    return service.get(apiUrl.category.getTypeList, data, {
      mock: false
    })
  },
  getGoodsList (data) {
    return service.get(apiUrl.category.getGoodsList, data, {
      mock: false
    })
  },
  getGoodsListByGoodType (data) {
    return service.get(apiUrl.category.getGoodsListByGoodType, data, {
      mock: false
    })
  },
  OriginDirectSelling (data) {
    return service.get(apiUrl.category.OriginDirectSelling, data, {
      mock: false
    })
  },
}
//商品相关
const goods = {
  getInfo (data) {
    return service.get(apiUrl.goods.getDetail, data, {
      mock: false
    })
  },
  followAdd (data) {
    return service.post(apiUrl.goods.followAdd, data, {
      mock: false
    })
  },
  followAdds (data) {
    return service.post(apiUrl.goods.followAdds, data, {
      mock: false
    })
  },
  followDels (data) {
    return service.post(apiUrl.goods.followDels, data, {
      mock: false
    })
  },

}
//购物车相关
const cart = {
  getList (data) {
    return service.get(apiUrl.cart.getList, data, {
      mock: false
    })
  },
  addGoods (data) {
    return service.post(apiUrl.cart.add, data, {
      mock: false
    })
  },
  delGoods (data) {
    return service.post(apiUrl.cart.del, data, {
      mock: false
    })
  },
  updataGoods (data) {
    return service.post(apiUrl.cart.updata, data, {
      mock: false
    })
  }
}
//订单相关
const order = {
  getRefundInfo (data) {
    return service.post(apiUrl.order.refund, data, {
      mock: false
    })
  },
  refundApply (data) {
    return service.post(apiUrl.order.refundApply, data, {
      mock: false
    })
  },
  getMapList (data) {
    return service.get(apiUrl.order.getMapList, data, {
      mock: false
    })
  },
  getInfo (data) {
    return service.get(apiUrl.order.getInfo, data, {
      mock: false
    })
  },
  previewByCart (data) {
    return service.get(apiUrl.order.previewByCart, data, {
      mock: false
    })
  },
  createByCart (data) {
    return service.post(apiUrl.order.createByCart, data, {
      mock: false
    })
  },
  previewBySingle (data) {
    return service.get(apiUrl.order.previewBySingle, data, {
      mock: false
    })
  },
  createBySingle (data) {
    return service.post(apiUrl.order.createBySingle, data, {
      mock: false
    })
  },
  getExpressList (data) {
    return service.get(apiUrl.order.getExpressList, data, {
      mock: false
    })
  },
  getOrderViewInfo (data) {
    return service.get(apiUrl.order.getOrderViewInfo, data)
  },
  receiveOrderGoods (data) {
    return service.post(apiUrl.order.receiveOrderGoods, data)
  },
}
const courier = {
  getUserInfo (data) {
    return service.get(apiUrl.courier.getUserInfo, data, {
      mock: false
    })
  },
  confirmSendToSitePoints(data){
    return service.post(apiUrl.courier.confirmSendToSitePoints, data, {
      mock: false
    })
  },
  getDistributionOrderList(data){
    return service.get(apiUrl.courier.getDistributionOrderList, data, {
      mock: false
    })
  },
  confirmDistributionOrder(data) {
    return service.post(apiUrl.courier.confirmDistributionOrder, data, {
      mock: false
    })
  },
  getNoticeList (data) {
    return service.get(apiUrl.courier.getNoticeList, data, {
      mock: false
    })
  },
  getNoticeOrderList (data) {
    return service.get(apiUrl.courier.getNoticeOrderList, data, {
      mock: false
    })
  },
  getSendList (data) {
    return service.get(apiUrl.courier.getSendList, data, {
      mock: false
    })
  },
  getSendNoticeList (data) {
    return service.get(apiUrl.courier.geSendNoticeList, data, {
      mock: false
    })
  },
  getDoneOrderList (data) {
    return service.get(apiUrl.courier.geDoneOrderList, data, {
      mock: false
    })
  },
  geDoneOrderInfo (data) {
    return service.get(apiUrl.courier.geDoneOrderInfo, data, {
      mock: false
    })
  },
  geScanInfo (data) {
    return service.get(apiUrl.courier.geScanInfo, data, {
      mock: false
    })
  },
  makeSure (data) {
    return service.post(apiUrl.courier.makeSure, data, {
      mock: false
    })
  },
  makeFlowCode (data) {
    return service.post(apiUrl.courier.makeFlowCode, data, {
      mock: false
    })
  },
  confirmGetGoods (params) {
    return service.post(apiUrl.courier.confirmGetGoods, params, {
      mock: false
    })
  },
  getSendReceiveCode (params) {
    return service.post(apiUrl.courier.getSendReceiveCode, params, {
      mock: false
    })
  },
  getBackGoodsList (params) {
    return service.get(apiUrl.courier.getBackGoodsList, params, {
      mock: false
    })
  },
  deliveryCode (params) {
    return service.get(apiUrl.courier.deliveryCode, params, {
      mock: false
    })
  },
  deliveryAfterCode (params) {
    return service.get(apiUrl.courier.deliveryAfterCode, params, {
      mock: false
    })
  },
  getSendReceiveInfo (params) {
    return service.get(apiUrl.courier.getSendReceiveInfo, params, {
      mock: false
    })
  },
  confirmSitePointReceiveGoods (params) {
    return service.post(apiUrl.courier.confirmSitePointReceiveGoods, params, {
      mock: false
    })
  },
  CanWithDraw (params) {
    return service.get(apiUrl.courier.CanWithDraw, params, {
      mock: false
    })
  },
  doWithDraw (params) {
    return service.get(apiUrl.courier.doWithDraw, params, {
      mock: false
    })
  },
  doWithDrawLog (params) {
    return service.get(apiUrl.courier.doWithDrawLog, params, {
      mock: false
    })
  }
}

const points = {
  myCenterInfo (data) {
    return service.get(apiUrl.points.myCenterInfo, data, {
      mock: false
    })
  },
  pointFinishOrder (data) {
    return service.get(apiUrl.points.pointFinishOrder, data, {
      mock: false
    })
  },
  pointUnFinishOrder (data) {
    return service.get(apiUrl.points.pointUnFinishOrder, data, {
      mock: false
    })
  },
  pointOnWay (data) {
    return service.get(apiUrl.points.pointOnWay, data, {
      mock: false
    })
  },
  pointOverOrder (data) {
    return service.get(apiUrl.points.pointOverOrder, data, {
      mock: false
    })
  },
  pointWillSendOrder (data) {
    return service.get(apiUrl.points.pointWillSendOrder, data, {
      mock: false
    })
  },
  pointSerarchCode (data) {
    return service.get(apiUrl.points.pointSerarchCode, data, {
      mock: false
    })
  },
  pointOrderInfo (data) {
    return service.get(apiUrl.points.pointOrderInfo, data, {
      mock: false
    })
  },
  pointOrderList (data) {
    return service.get(apiUrl.points.pointOrderList, data, {
      mock: false
    })
  },
  makeSureSend (data) {
    return service.post(apiUrl.points.makeSureSend, data, {
      mock: false
    })
  },
  makeSureReceive (data) {
    return service.post(apiUrl.points.makeSureReceive, data, {
      mock: false
    })
  },
  getStoresStatistics (data) {
    return service.get(apiUrl.points.getStoresStatistics, data, {
      mock: false
    })
  },
  getPointStockUpNums (data) {
    return service.get(apiUrl.points.getPointStockUpNums, data, {
      mock: false
    })
  },
  platformProfitByPoint (data) {
    return service.post(apiUrl.points.platformProfitByPoint, data, {
      mock: false
    })
  },
  platformProfitByGoods (data) {
    return service.get(apiUrl.points.platformProfitByGoods, data, {
      mock: false
    })
  },
  statisticsPointsDownload (data) {
    return service.get(apiUrl.points.statisticsPointsDownload, data, {
      mock: false
    })
  },
  makeSurePlReceive (data) {
    return service.post(apiUrl.points.makeSurePlReceive, data, {
      mock: false
    })
  },
  CanWithDraw (data) {
    return service.get(apiUrl.points.CanWithDraw, data, {
      mock: false
    })
  },
  doWithDraw (data) {
    return service.post(apiUrl.points.doWithDraw, data, {
      mock: false
    })
  },
  doWithDrawLog (data) {
    return service.get(apiUrl.points.doWithDrawLog, data, {
      mock: false
    })
  },
  getStatisticsAuth (data) {
    return service.get(apiUrl.points.getStatisticsAuth, data, {
      mock: false
    })
  },
  getSendReceiveInfo (params) {
    return service.get(apiUrl.points.getSendReceiveInfo, params, {
      mock: false
    })
  },
  confirmReceiveGoods (params) {
    return service.post(apiUrl.points.confirmReceiveGoods, params, {
      mock: false
    })
  },
  pointSearchUser (data) {
    return service.get(apiUrl.points.pointSearchUser, data)
  },
  pointSetWorker (data) {
    return service.post(apiUrl.points.pointSetWorker, data)
  },
  pointWorkerList (data) {
    return service.get(apiUrl.points.pointWorkerList, data)
  },
  pointRemoveWorker (data) {
    return service.post(apiUrl.points.pointRemoveWorker, data)
  },
  pointWillReceiveGoods (data) {
    return service.get(apiUrl.points.pointWillReceiveGoods, data)
  },
  getPointSendReceiveCode (data) {
    return service.post(apiUrl.points.getPointSendReceiveCode, data)
  }
}
const stores = {
  orderCatalog (data) {
    return service.get(apiUrl.stores.orderCatalog, data, {
      mock: false
    })
  },
  mallOrder (data) {
    return service.get(apiUrl.stores.mallOrder, data, {
      mock: false
    })
  },
  mallOrderInfo (data) {
    return service.get(apiUrl.stores.mallOrderInfo, data, {
      mock: false
    })
  },
  expressList(data) {
    return service.get(apiUrl.stores.expressList, data, {
      mock: false
    })
  },
  setOrderExpress(data) {
    return service.post(apiUrl.stores.setOrderExpress, data, {
      mock: false
    })
  }
}

const users = {
  getUserMoney (data) {
    return service.get(apiUrl.users.userMoney, data, {
      mock: false
    })
  },
}

const storeJoins = {
  goodsList(data){
    return service.get(apiUrl.storeJoins.goodsList, data, {
      mock: false
    })
  },
  orderView(data){
    return service.get(apiUrl.storeJoins.orderView,data,{
      mock:false
    })
  },
  createStoreJoinOrder(data){
    return service.post(apiUrl.storeJoins.createStoreJoinOrder,data,{
      mock:false
    })
  },
  getStoreJoinOrderInfo(data){
    return service.get(apiUrl.storeJoins.getStoreJoinOrderInfo,data,{
      mock:false
    })
  },
  getJoinGoodsUseCode(data){
    return service.get(apiUrl.storeJoins.getJoinGoodsUseCode,data,{
      mock:false
    })
  }
}

export default {
  home,
  search,
  category,
  goods,
  cart,
  order,
  courier,
  points,
  stores,
  users,
  storeJoins
}
