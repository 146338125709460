import {
	mapState
} from 'vuex';
import store from './index.js';


let $mapStateList = [];
try {
	$mapStateList = store.state ? Object.keys(store.state).filter((val) => val.indexOf('vuex_') >= 0) : [];
} catch (e) {
	//异常
}

//默认模块
const files = require.context('@/store/modules/common', false, /\.js$/, 'sync')
let defaultVuex = [];
files.keys().forEach((key) => {
	key = key.replace(/^\.|\.js$/gi, '').replace(/\//, 'common_');
	defaultVuex.push(key);
})

var defaultVuexStates = {};
defaultVuex.map((val) => {
	Object.assign(defaultVuexStates, {
		...mapState([val])
	})
})

function setVuexCommit(vuexName, vm) {
	vm.$vuex[vuexName] = (name, value) => {
		store.commit(`${vuexName}/updataStore`, {
			name,
			value
		})
	}
}

//定义混淆
export default {
	needVuex: false,
	configVuex: [],
	beforeCreate() {
		// 将vuex方法挂在到$vuex中
		// 使用方法为：如果要修改root的state中的user.name变量为"史诗" => this.$vuex.root('user.name', '史诗')
		// 如果要修改root的state的version变量为1.0.1 => this.$vuex.root('version', '1.0.1')
		this.$vuex['root'] = (name, value) => {
			this.$store.commit('updataStore', {
				name,
				value
			})
		}
		defaultVuex.map((val) => {
			setVuexCommit(val, this);
		})
		if (this.$options.needVuex) {
			//如果需要vuex 才会引入 默认不需要
			let configVuex = this.$options.configVuex;
			//configVuex 配置的是当前页面需要控制的vuex
			configVuex.forEach((vuexName) => {
				if (store.hasModule(vuexName)) {
					setVuexCommit(vuexName, this);
				}
			})
		}
	},
	computed: {
		// 将vuex的state中的所有变量，解构到全局混入的mixin中（这里只解构了根目录)
		...mapState($mapStateList),
		...defaultVuexStates
	},
}