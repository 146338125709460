import appSetting from '../../appSetting'
/**
 * 环境配置
 */
const env = appSetting.isDevelopment  ? 'dev' : 'prod'

/**
 * 不同环境的api
 */
const EnvConfig = {
    dev: {
        // baseApi: '/api',
        baseApi: 'http://api.rsck.group/',
        // baseApi: 'https://api.mengjingloulan.com/',
        mockApi: '/mock'
    },
    prod: {
        // baseApi: 'http://api.rsck.group/',//测试服务器地址
        baseApi: 'https://api.mengjingloulan.com/', //线上服务器地址
        mockApi: '/mock'
    }
}
/**
 * api地址
 */
const apiUrl = {
    //合同相关接口
    contract: {
        getInfo: 'contract/info', //获取详情
        makesure: 'contract/makesure', //签名
    }
}
/**
 * api接口规则
 */
const rules = {

}

export default {
    mock: false,
    env,
    apiUrl,
    rules,
    ...EnvConfig[env],
}