import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import router from '@/router'

import VueCoreVideoPlayer from 'vue-core-video-player'

Vue.use(VueCoreVideoPlayer)

// //引入地图插件
import VueAMap from 'vue-amap'; // 高德地图
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: "263b744829d1b92c0e7168b028466a34",
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'MarkerClusterer'],
  v: '1.4.4'
})

import LuckDraw from 'vue-luck-draw'


// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// console.log('process',process)

Vue.config.productionTip = false;
import 'normalize.css/normalize.css'

//适配移动端自适应
import 'amfe-flexible'

//引入ui组件库
import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';

NutUI.install(Vue);
//引入utils
import utils from '@U/index.js';

//引入api
import api from '@A';

//引入mock
import "@/mock";

import storeMixin from '@/store/mixin.js';

//platform
import platform from '@/platform/index.js';

//引入弹窗
import showCodeV1 from "@/uicomponent/popCode/codeV1.js";
Vue.prototype.$showCodeV1 = showCodeV1;

//引入图片预览
import previewImg from "@/uicomponent/previewImg/previewImg.js";
Vue.prototype.$previewImg = previewImg;

//导入scrollview

import scrollview from "@/uicomponent/scrollView/scorllview";
Vue.component('scrollview', scrollview);

Vue.use(LuckDraw)

Vue.use(platform);
Vue.use(utils);
Vue.use(api);
Vue.mixin(storeMixin);
new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount('#app');