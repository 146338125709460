const files = require.context('@U', true, /\.js$/, 'sync');

const $utils = {};
files.keys().forEach((key) => {
    let funcName = key.replace(/^((\.\/function\/)|(\.\/))|\.js$/gi, '');
    if (funcName != 'index') {
        $utils[funcName] = files(key).default;
    }
});

console.log($utils)

import debounce from "./directive/debounce.js";
import throttle from "./directive/throttle.js";
// import loadingV1 from '@/uicomponent/toast/loading/loadingV1';

const install = (Vue) => {
    Vue.use(debounce);
    Vue.use(throttle);
    Vue.prototype.$vuex = {};
    Vue.filter('timeFormat', (timestamp, format) => $utils.date.timeFormat(timestamp, format))
    Vue.filter('date', (timestamp, format) => $utils.date.timeFormat(timestamp, format))
    // 将多久以前的方法，注入到全局过滤器
    Vue.filter('timeFrom', (timestamp, format) => $utils.date.timeFrom(timestamp, format))
    Vue.prototype.$utils = $utils;
    window.$utils = $utils;
}
export default {
    install
}