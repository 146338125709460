<template>
    <div class="load-moudle">
        <slot></slot>
        <footer ref="loadmore" class="load-more">
            <slot name="load-more">
                <div class="loading-tip" v-if="pullUpState == 1">
                    <span class="loading-icon"></span>
                    <span class="loading-text">{{ pullUpInfo.loadingText }}</span>
                </div>
                <div class="no-more-tip" v-if="pullUpState == 2">
                    <span class="connecting-line"></span>
                    <span class="no-more-text">{{ pullUpInfo.noMoreText }}</span>
                    <span class="connecting-line"></span>
                </div>
            </slot>
        </footer>
    </div>
</template>

<script>
  export default {
    props: {
      parentPullUpState: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        top: 0,
        pullUpState: 0, // 1:数据加载中..., 2:没有更多数据了
        pullUpInfo: {
          loadingText: '数据加载中...', //加载中提示
          noMoreText: '已经加载全部数据', //如果没有更多数据时候的提示
        },
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        scrollview: null,
      }
    },
    watch: {
      parentPullUpState () {
        this.pullUpState = this.$props.parentPullUpState
      },
    },
    mounted () {
      this.$nextTick(() => {
        this.pullUpState = this.$props.parentPullUpState
        this.createScroll()
      })
    },
    methods: {
      createScroll () {
        this.scrollview = document.querySelector('.scrollview_item')
        this.scrollview.scrollTop = 0
        this.scrollview.onscroll = (e) => {
          let offsetHeight = this.scrollview.offsetHeight
          let scrollTop = e.target.scrollTop
          let scrollHeight = this.scrollview.scrollHeight
          if (offsetHeight + scrollTop >= scrollHeight - 9) {
            if (this.pullUpState !== 1) {
              this.infiniteLoad()
            }
          }
        }
      },
      /**
       * 上拉加载数据
       */
      infiniteLoad () {
        if (this.pullUpState !== 1 && this.pullUpState !== 2) {
          this.pullUpState = 1
          this.$emit('reload')
        }
      },

      /**
       * 根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
       * @param {number} startX X轴开始位置
       * @param {number} startY X轴结束位置
       * @param {number} endX Y轴开始位置
       * @param {number} endY Y轴结束位置
       */
      getSlideDirection (startX, startY, endX, endY) {
        let dy = startY - endY
        let dx = endX - startX
        let result = 0
        // 如果滑动距离太短
        if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
          return result
        }
        let angle = this.getSlideAngle(dx, dy)
        if (angle >= -45 && angle < 45) {
          result = 4
        } else if (angle >= 45 && angle < 135) {
          result = 1
        } else if (angle >= -135 && angle < -45) {
          result = 2
        } else if (
          (angle >= 135 && angle <= 180) ||
          (angle >= -180 && angle < -135)
        ) {
          result = 3
        }
        return result
      },
    },
    destroyed () {
      this.scrollview.onscroll = () => {}
      this.scrollview = null
    },
  }
</script>
<style scoped>
    .load-more {
        width: 100%;
        color: #c0c0c0;
        font-size: 12px;
    }

    .more-tip,
    .loading-tip,
    .no-more-tip {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    .load-moudle .loading-icon {
        display: inline-flex;
        width: 35px;
        height: 35px;
        background-size: cover;
        margin-right: 5px;
        animation: rotating 2s linear infinite;
    }

    @keyframes rotating {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(1turn);
        }
    }

    .connecting-line {
        display: inline-flex;
        width: 60px;
        height: 2px;
        margin-left: 20px;
        margin-right: 20px;
        border-bottom: #ddd dashed 1px;
    }
</style>
