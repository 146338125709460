//自提点路由
const selfExtract = [{
    path: 'self-extract-home',
    name: 'selfExtractHome',
    component: () => import('@/pages/selfExtract/home-new.vue')
  },
  {
    path: 'order-list',
    name: 'selfExtractOrderList',
    component: () => import('@/pages/selfExtract/order-list.vue')
  },
  {
    path: 'scan-delivery-result',
    name: 'selfExtractScanDeliveryResult',
    component: () => import('@/pages/selfExtract/scan-delivery-result.vue')
  },
  {
    path: 'scan-delivery-result-batch',
    name: 'selfExtractScanDeliveryResultBatch',
    component: () => import('@/pages/selfExtract/scan-delivery-result-batch.vue')
  },
  {
    path: 'scan-pl-delivery-result',
    name: 'selfExtractScanPlDeliveryResult',
    component: () => import('@/pages/selfExtract/scan-pl-delivery-result.vue')
  },
  {
    path: 'scan-user-result',
    name: 'selfExtractScanUserResult',
    component: () => import('@/pages/selfExtract/scan-user-result.vue')
  },
  {
    path: 'done-order-list',
    name: 'selfExtractDoneOrderList',
    component: () => import('@/pages/selfExtract/done-order-list.vue')
  },
  {
    path: 'with-drawal-log',
    name: 'selfExtractDoneOrderList',
    component: () => import('@/pages/selfExtract/with-drawal-log.vue')
  },
  {
    path: 'with-drawal',
    name: 'selfExtractDoneOrderList',
    component: () => import('@/pages/selfExtract/with-drawal.vue')
  },
  {
    path: 'statistics',
    name: 'statistics',
    component: () => import('@/pages/selfExtract/statistics.vue')
  },
  {
    path: 'statistics-details',
    name: 'statistics-details',
    component: () => import('@/pages/selfExtract/statistics-details.vue')
  },
  {
    path: 'money-statistics',
    name: 'money-statistics',
    component: () => import('@/pages/selfExtract/money-statistics.vue')
  },
  {
    path: 'statistics-points-download',
    name: 'statistics-points-download',
    component: () => import('@/pages/selfExtract/statistics-points-download.vue')
  },
  {
    path:'worker/add-worker',
    component: () => import('@/pages/selfExtract/worker/add-worker.vue')
  },
  {
    path:'worker/search-user',
    component: () => import('@/pages/selfExtract/worker/search-user.vue')
  },
  {
    path:'worker/worker-list',
    component: () => import('@/pages/selfExtract/worker/worker-list.vue')
  },
  {
    path:'sending-goods',
    component: () => import('@/pages/selfExtract/sending-goods.vue')
  }
]
export default selfExtract