 let throttles={};
/**
 * 节流原理：在一定时间内，只能触发一次
 * @param {String} funcName 方法名称 
 * @param {Function} func 要执行的回调函数 
 * @param {Number} delay 延时的时间
 * @param {Boolean} immediate 是否立即执行
 */
function _throttle(funcName,func, delay = 500, immediate = true) {
	let timer=null;
	 return function (){
		if (immediate) {
			if (!timer) {
				// 则在delay毫秒内开始时执行
				typeof func === 'function' && func();
				timer = setTimeout(() => {
					//执行完毕 销毁闭包 避免造成内存泄漏
					delete throttles[funcName];
					timer = null;
				}, delay);
			}
		} else {
			// 则在delay毫秒内的结束处执行
			if (!timer) {
				timer = setTimeout(() => {
					//执行完毕 销毁闭包 避免造成内存泄漏
					delete throttles[funcName];
					timer = null
					typeof func === 'function' && func();
				}, delay);
			}
		}
	}
};
/**
 * @param {String} funcName 方法名称 
 * @param {Function} func 方法体 
 * @param {delay} delay 延时时间
 * @param {String} immediate 是否立即执行 默认立即执行 
 * @example throttle("sum",function()=>{return x+y},1000,true)
 */
function throttle(funcName,func,delay=500,immediate=true){
	if(throttles.hasOwnProperty(funcName)){
		throttles[funcName]();
		return
	}
	throttles[funcName]=_throttle.apply(this,arguments);
	throttles[funcName]();
}
export default throttle;
// /**
//  * @desc 函数节流
//  * @param func 函数
//  * @param wait 延迟执行毫秒数
//  * @param type 1 使用时间戳，在时间段开始的时候触发 2 使用定时器，在时间段结束的时候触发
//  * @example  let getName=throttle(()=>{return 'throttle'},1000)  getName();
//  */
// export const throttle = (func, wait = 1000, type = 1) => {
// 	let previous = 0;
// 	let timeout;
// 	console.log(previous);
// 	return function() {
// 		let context = this;
// 		let args = arguments;
// 		if (type === 1) {
// 			let now = Date.now();
			
// 			if (now - previous > wait) {
// 				func.apply(context, args);
// 				previous = now;
// 			}
// 		} else if (type === 2) {
// 			if (!timeout) {
// 				timeout = setTimeout(() => {
// 					timeout = null;
// 					func.apply(context, args)
// 				}, wait)
// 			}
// 		}
// 	}
// }

