/*
 * @Author: liuyong 896291133@qq.com
 * @Date: 2022-05-18 12:03:26
 * @LastEditors: liuyong 896291133@qq.com
 * @LastEditTime: 2022-05-28 13:50:33
 * @FilePath: /rsck-wechat-web-2.0/src/router/routes/Courier.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//配送员路由
const Courier = [
  {
    path: 'courier-home',
    name: 'CourierHome',
    component: () => import('@/pages/courier/home.vue')
  },
  {
    path: 'courier-notice',
    name: 'CourierNotice',
    component: () => import('@/pages/courier/courier-notice.vue')
  },
  {
    path: 'courier-notice-list',
    name: 'CourierNoticeList',
    component: () => import('@/pages/courier/courier-notice-list.vue')
  },
  {
    path: 'send-notice',
    name: 'CourierSendNotice',
    component: () => import('@/pages/courier/send-notice.vue')
  },
  {
    path: 'order-list',
    name: 'CourierOrderList',
    component: () => import('@/pages/courier/done-order-list.vue')
  },
  {
    path: 'send-notice-list',
    name: 'CourierSendNoticeList',
    component: () => import('@/pages/courier/send-notice-list.vue')
  },
  //send-notice-list
  {
    path: 'send-order-list',
    name: 'CourierSendOrderList',
    component: () => import('@/pages/courier/send-order-list.vue')
  },
  {
    path: 'order-info',
    name: 'CourierOrderInfo',
    component: () => import('@/pages/courier/order-info.vue')
  },
  {
    path: 'scan-result',
    name: 'CourierScanResult',
    component: () => import('@/pages/courier/scan-result.vue')
  },
  {
    path:'back-goods',
    name:"backGoods",
    component:() => import('@/pages/courier/back-goods.vue')
  },
  {
    path:'scan-delivery-result-batch',
    name:"courierScanDeliveryResultBatch",
    component:() => import('@/pages/courier/scan-delivery-result-batch.vue')
  },
  {
    path:'money-center',
    name:"moneyCenter",
    component:() => import('@/pages/courier/money-center.vue')
  },
  {
    path:'with-drawal-log',
    name:"withdrawalLog",
    component:() => import('@/pages/courier/with-drawal-log.vue')
  },
  {
    path:'distribution',
    name:"distribution",
    component:() => import('@/pages/courier/distribution.vue')
  }
]
export default Courier