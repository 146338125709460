//活动路由
const Actives = [
  {
    path: 'actives-turn-table',
    name: 'ActivesHome',
    component: () => import('@/pages/actives/turn-table.vue')
  },
  {
    path: 'gift-log',
    name: 'ActivesGiftLog',
    component: () => import('@/pages/actives/gift-log.vue')
  }
]
export default Actives