import request from '@U/request.js';
import config from '@A/common/config.js';
import interceptRequest from '@A/common/intercept/request.js';
import interceptResponse from '@A/common/intercept/response.js';
const {
    apiUrl,
} = config;

const service = new request(config, interceptRequest, interceptResponse);

//微信sdk
const jsweixin = {
    getConfig(data) {
        return service.get(apiUrl.jsweixin.getConfig, data);
    },
    login() {
        return service.get(apiUrl.jsweixin.login);
    },
    getUserInfo() {
        return service.get(apiUrl.jsweixin.getUserInfo);
    }
}
export default {
    jsweixin,
}