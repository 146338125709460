import request from '@U/request.js';
import config from '@A/contract/config.js';
import interceptRequest from '@A/contract/intercept/request.js';
import interceptResponse from '@A/contract/intercept/response.js';
const {
    apiUrl,
} = config;

const service = new request(config, interceptRequest, interceptResponse);

//合同
const renew = {
    getInfo(data) {
        return service.get(apiUrl.contract.getInfo, data, {
            mock: false
        });
    },
    makesure(data) {
        return service.post(apiUrl.contract.makesure, data);
    }
}

export default {
    renew
}