import {
    Random
} from 'mockjs';
const Mock = require('mockjs');
/**
 * 合同相关的
 */
const contract = {
    /**
     * 获取合同信息接口 
     * @type get
     * @param {Number} re_sign 默认值为0 重新审核的值为1
     * @param {Number}  user_id      用户id 
     * @returns 
     */
    info: {
        type: "get",
        func: (res) => {
            console.log('info.接收到的参数', res);
            return {
                error: 0,
                message: "请求成功",
                data: {
                    start_time: "2021年10月30", //合同开始时间
                    end_time: "2022年10月30", //合同截止时间
                    examine_text: "新添加草稿合同", //小程序通知弹窗提示语句
                    first_party: "梦回楼兰", //梦回楼兰印章url
                    id: "13", //合同id
                    second_party: "", //入驻合同签名url
                    second_appendix_party: "", //附加合同签名url
                    sign_time: "2021-10-30", //签署时间（当前签订合同的时间）
                    status: 0, //0是初始状态 1审核中  2审核通过 3审核失败
                    store_id: 205, //商户id
                    //附加合同信息
                    supplement: {
                        //入驻合同
                        report: {
                            tel: "182123456456", //举报电话
                            email: "rsck123@163.com" //举报邮箱
                        },
                        //附加合同
                        discount: "合作折扣", //合作折扣
                        remarks: "未尽事宜补充", //未尽事宜补充
                        need_list: [], //乙方需提供资料
                        storeinfo: [], //店铺信息
                    }

                }
            }
        }
    },
    /**
     * 签名接口
     * @type post
     * @param {Number} id 当前合同的id
     * @param {String} baseinfo 入驻合同签名base64id
     * @param {String} second_appendix_party 附加合同签名base64id
     * @param {Number}  user_id      用户id 
     * @returns 
     */
    makesure: {
        type: "post",
        func: (res) => {
            console.log('makesure.接收到的参数', res);
            return {
                error: 0,
                message: "请求成功",
                data: Mock.mock({})
            }
        }
    }
}
export default contract;