/***
 *  节流 每单位时间可触发一次
 *  第一次瞬间触发，最后一次不管是否达到间隔时间依然触发
 * 【考虑到input的change事件】
 *  @param {?String|"click"} event - 事件类型 例："click"
 *  @param {Function} fn - 执行事件
 *  @param {?Number|300} time - 间隔时间
 *  @param {Array} binding.value - [fn,event,time]
 *  例：<button v-throttle="[`click`,callback,300]">节流</button>
 *  传递参数则：<button v-throttle="[`click`,callback(param),300]">节流</button>
 */

export default (Vue) => {
    console.log('throttle');
    Vue.directive('throttle', {
        inserted: function (el, binding) {
            console.log('throttle', binding);
            let [event = "click", fn, time = 1000] = binding.value
            let timer = null,
                timer_end = null;
            el.addEventListener(event, () => {
                if (timer) {
                    clearTimeout(timer_end);
                    return timer_end = setTimeout(() => fn(), time);
                }
                fn();
                timer = setTimeout(() => timer = null, time)
            })
        }
    })
}