import Cookies from 'js-cookie';
import {
	updataStore
} from '@/store/common.js';
let lifeData = {};

try {
	// 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
	lifeData = Cookies.get('lifeData_common_tabbar');
} catch (e) {

}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = ['current', 'list'];
export default {
	namespaced: true,
	state: {
		current: lifeData?.current || 0,
		list: lifeData?.list || [{
				tabTitle: "主页",
				tabName: "Home",
				curr: true,
				icon: "http://img13.360buyimg.com/uba/jfs/t1/29316/38/1115/3203/5c0f3d61E35d0c7da/9e557f2cb5c9dab6.jpg",
				activeIcon: "http://img20.360buyimg.com/uba/jfs/t1/9996/36/8646/4833/5c0f3d61E7c1b7e0f/c98ad61124172e93.jpg",
				href: "###",
			},
			{
				tabTitle: "webview",
				tabName: "Webview",
				curr: false,
				icon: "http://img12.360buyimg.com/uba/jfs/t1/25443/23/1062/4600/5c0f3d61E2e9f1360/c9b3421fe18614e2.jpg",
				activeIcon: "http://img20.360buyimg.com/uba/jfs/t1/19241/12/1048/8309/5c0f3d61E17ed5a56/c3af0964cade47f8.jpg",
				href: "###",
			},
			{
				tabTitle: "发现",
				tabName: "Find",
				curr: false,
				icon: "http://img13.360buyimg.com/uba/jfs/t1/10361/35/4713/4643/5c0f3d62E437a3c94/273fd0fb90798f03.jpg",
				activeIcon: "http://img14.360buyimg.com/uba/jfs/t1/26604/35/1073/7896/5c0f3d61Eb9f5f184/5f01c938abe4216d.jpg",
				href: "###",
			},
			{
				tabTitle: "购物车",
				tabName: "Cart",
				curr: false,
				num: 2,
				icon: "http://img11.360buyimg.com/uba/jfs/t1/14848/18/1066/3723/5c0f41bdE9f2a38fe/e6ed6768717297fb.jpg",
				activeIcon: "http://img30.360buyimg.com/uba/jfs/t1/17538/16/1070/6214/5c0f41bdE4bc9a1db/74cf978e5015454b.jpg",
				href: "###",
			},
			{
				tabTitle: "我的",
				tabName: "Member",
				curr: false,
				icon: "http://img20.360buyimg.com/uba/jfs/t1/20004/20/1045/3620/5c0f3d61Eaaec1670/9e59db63983b7b9f.jpg",
				activeIcon: "http://img14.360buyimg.com/uba/jfs/t1/23967/14/1072/6714/5c0f3d61E0ad8991e/8f741953f6e38f15.jpg",
				href: "###",
			},
		],
	},
	mutations: {
		updataStore(state, payload) {
			updataStore('common_tabbar', saveStateKeys, state, payload);
		}
	},
	actions: {
		async switch ({
			state,
			commit,
			dispatch,
			rootState,
		}, params) {
			console.log('updataSwitch', params);
			console.log('token', rootState.vuex_token);
			console.log('value', value);
			console.log(index);
			this.tabCurrent = index;

			console.log(tabList);
			// if (info.code == 1) {
			// 	commit('updataStore',{name:'userInfo',value:info.data});
			// 	dispatch('tabbar/initTabbar',null,{root:true});
			// }

		},
		async updataUserInfo({
			state,
			dispatch,
			commit,
			rootState,
		}, params) {
			console.log('updataUserInfo', params, );
			await dispatch('getUserInfo');
		}
	},
	getters: {}
}