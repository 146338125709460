//社区服务路由
const Services = [
  {
    path: 'home',
    name: 'ServiceHome',
    component: () => import('@/pages/services/home.vue')
  },
  {
    path: 'goods',
    name: 'ServiceGoods',
    component: () => import('@/pages/services/goods.vue')
  },
  {
    path: 'add-service',
    name: 'ServiceAdd',
    component: () => import('@/pages/services/add-service.vue')
  },
  {
    path: 'my-service',
    name: 'ServiceMy',
    component: () => import('@/pages/services/my-service.vue')
  },
  {
    path: 'goods-info',
    name: 'ServiceGoodsInfo',
    component: () => import('@/pages/services/goods-info.vue')
  },
  {
    path: 'pay-success',
    name: 'ServicePaySuccess',
    component: () => import('@V/services/pay-success.vue')
  },
  {
    path: 'pay-failed',
    name: 'ServicePaySuccess',
    component: () => import('@V/services/pay-failed.vue')
  },
  {
    path: 'order-info',
    name: 'OrderInfo',
    component: () => import('@V/services/order-info.vue')
  },
  {
    path: 'order-confirm',
    name: 'ServiceConfirmOrder',
    component: () => import('@/pages/services/order-confirm.vue')
  },
  {
    path: 'refund',
    name: 'ServiceRefund',
    component: () => import('@/pages/services/refund.vue')
  },
  {
    path: 'my-services-orders',
    name: 'myServicesOrders',
    component: () => import('@/pages/services/my-services-orders.vue')
  },
  {
    path: 'my-services-orders-info',
    name: 'myServicesOrdersInfo',
    component: () => import('@/pages/services/my-services-orders-info.vue')
  },
  {
    path: 'search',
    name: 'serviceSearch',
    component: () => import('@/pages/services/search.vue')
  },
  {
    path: 'search-list',
    name: 'serviceSearchList',
    component: () => import('@/pages/services/searchList.vue')
  },
]
export default Services