import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie';
import vuex_modules from "@/store/modules";
import {
  updataStore,
  saveLifeData
} from './common.js';
Vue.use(Vuex);

let lifeData = {};

try {
  // 尝试获取本地是否存在lifeData变量，第一次启动APP时是不存在的
  lifeData = Cookies.get('lifeData_root');
} catch (e) {
  //异常
}
// 需要永久存储，且下次APP启动需要取出的，在state中的变量名
let saveStateKeys = [];

export default new Vuex.Store({
  state: {
    vuex_base: '111'
  },
  mutations: {
    updataStore(state, payload) {
      updataStore('root', saveStateKeys, state, payload);
    }
  },
  actions: {},
  modules: vuex_modules
})