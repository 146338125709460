//获取所有的子路由
const routesFiles = require.context('@/router/routes', false, /\.js$/, 'sync')
const routeChildrens = {}
routesFiles.keys().forEach((key) => {
  if (!/\.\/Root\.js/g.test(key)) {
    let routeName = key.replace(/^\.\/|\.js$/g, '')
    routeChildrens[routeName] = routesFiles(key).default
  }
})

//获取所有的单个路由拦截配置项
const beforeEnterFile = require.context('@/router/beforeEnter', false, /\.js$/, 'sync')
const routeBeforeEnters = {}
beforeEnterFile.keys().forEach((key) => {
  let beforeEnterName = key.replace(/^\.\/|\.js$/g, '')
  routeBeforeEnters[beforeEnterName] = beforeEnterFile(key).default
})

const routes = [{
  path: '/contract',
  name: 'Contract',
  component: () => import('@/pages/contract/index.vue'),
  children: [],
},
  {
    path: '/common',
    name: 'Common',
    component: () => import('@/pages/common/index.vue'),
    children: [],
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import('@/pages/community/index.vue'),
    children: [],
  },
  {
    path: '/courier',
    name: 'Courier',
    component: () => import('@/pages/courier/index.vue'),
    children: [],
  },
  {
    path: '/selfExtract',
    name: 'selfExtract',
    component: () => import('@/pages/selfExtract/index.vue'),
    children: [],
  },
  {
    path: '/stores',
    name: 'Stores',
    component: () => import('@/pages/stores/index.vue'),
    children: [],
  },
  {
    path: '/coupon-card',
    name: 'couponCard',
    component: () => import('@/pages/coupon_card/index.vue'),
    children: [],
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/pages/services/index.vue'),
    children: [],
  },
  {
    path: '/actives',
    name: 'Actives',
    component: () => import('@/pages/actives/index.vue'),
    children: [],
  },
]

//设置对应路由的子路由
function setChildrens (route, children) {
  if (!route.name) {
    return
  }

  if (children[route.name] && children[route.name].length > 0) {
    route.children = children[route.name]
  }
}

//设置对应路由的拦截
function setBeforeEnter (route, beforeEnter) {
  if (!route.name) {
    return
  }
  if (!beforeEnter[route.name]) {
    return
  }
  let _beforeEnter = beforeEnter[route.name]
  if (typeof _beforeEnter?.default === 'function') {
    route.beforeEnter = _beforeEnter.default
  }
  if (!route['children']) {
    return
  }
  if (route?.children.length > 0) {
    if (!_beforeEnter['children']) {
      return
    }
    if (typeof _beforeEnter?.children !== 'object') {
      return
    }
    if (Object.keys(_beforeEnter?.children).length == 0) {
      return
    }
    route.children.forEach((item) => {
      if (_beforeEnter.children[item.name]) {
        setBeforeEnter(item, _beforeEnter.children)
      }
    })
  }
}

//将所有子路由加入到routes中
routes.forEach((item) => {
  setChildrens(item, routeChildrens)
})

//将所有的子路由拦截加入到routes中
routes.forEach((item) => {
  setBeforeEnter(item, routeBeforeEnters)
})

export default routes