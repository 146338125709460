import request from '@U/request.js'
import config from '@A/actives/config.js'
import interceptRequest from '@A/actives/intercept/request.js'
import interceptResponse from '@A/actives/intercept/response.js'

const {
  apiUrl,
} = config

const service = new request(config, interceptRequest, interceptResponse)

const getTurnTableSetting = (data) => {
  return service.get(apiUrl.getTurnTableSetting, data)
}

const getTurnTableGiftRecord = (data) => {
  return service.get(apiUrl.getTurnTableGiftRecord, data)
}

const getTurnTableGift = (data) => {
  return service.get(apiUrl.getTurnTableGift, data)
}

const getGiftLog = (data) => {
  return service.get(apiUrl.getGiftLog, data)
}



export default {
  getTurnTableSetting,
  getTurnTableGiftRecord,
  getTurnTableGift,
  getGiftLog,
}