<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.initParams();
  },
  methods: {
    initParams() {
      let host = this.$utils.parseUrl.getHost();
      let header = this.$utils.parseUrl.getHeaderByParams();
      let params = this.$utils.parseUrl.getDataByParams();
      // console.log(this.$vuex);
      // console.log('header',header)
      // console.log('params',params)
      host && this.$vuex.common_platform("host", host);
      header && this.$vuex.common_platform("header", header);
      params && this.$vuex.common_platform("params", params);
    },
  },
};
</script>
<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
