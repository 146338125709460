/**
 * 
 * @param {*} type 类型 支持js和css
 * @param {*} src 地址 支持远程地址和本地地址
 * @returns 
 */
export default function (
     type = "js",
     src = ""
) {
     if (!src) {
          throw ("cdn地址不能为空");
     }
     return new Promise((resolve, reject) => {
          const dom = document.createElement('script');
          if (type == 'css') {
               dom.type = "text/css"
               dom.rel = 'stylesheet';
               dom.link = src;
               document.head.appendchild(dom);

          } else {
               dom.type = "text/javascript"
               dom.src = src;
               document.body.appendChild(dom);
          }
          dom.onerror = ()=> {
               reject();
          }
          dom.onload = () => {
               resolve();
          }
     })
}