import Cookies from 'js-cookie'
/**
 * 保存缓存
 * @param {Object} name					store.name
 * @param {Object} saveStateKeys		需要缓存的数据
 * @param {Object} key					键名
 * @param {Object} value				值
 */
function saveLifeData(name, saveStateKeys, key, value) {
	if (!name) {
		throw ('缺少name属性');
	}
	// 判断变量名是否在需要存储的数组中
	if (saveStateKeys.indexOf(key) != -1) {
		// 获取本地存储的lifeData对象，将变量添加到对象中
		let tmp = Cookies.get('lifeData_' + name);
		tmp = tmp ? JSON.parse(tmp) : {};
		tmp[key] = value;
		// 执行这一步后，所有需要存储的变量，都挂载在本地的lifeData对象中
		Cookies.set('lifeData_' + name, JSON.stringify(tmp), {
			expires: 7
		});
	}
}
/**
 * 提交mutation
 * @param {Object} name				storeName
 * @param {Object} saveStateKeys	需要缓存的数据
 * @param {Object} state			store.state
 * @param {Object} payload			参数
 */
export function updataStore(name, saveStateKeys, state, payload) {
  console.log('updataStore',payload);
	// 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
	let nameArr = payload.name.split('.');
	let saveKey = '';
	let len = nameArr.length;
	if (len >= 2) {
		let obj = state[nameArr[0]];
		for (let i = 1; i < len - 1; i++) {
			obj = obj[nameArr[i]];
		}
		obj[nameArr[len - 1]] = payload.value;
		saveKey = nameArr[0];
	} else {
		// 单层级变量，在state就是一个普通变量的情况
		state[payload.name] = payload.value;
		saveKey = payload.name;
	}
	// 保存变量到本地，见顶部函数定义
	saveLifeData(name, saveStateKeys, saveKey, state[saveKey]);
}