import request from '@U/request.js'
import config from '@A/couponCard/config.js'
import interceptRequest from '@A/couponCard/intercept/request.js'
import interceptResponse from '@A/couponCard/intercept/response.js'

const {
  apiUrl,
} = config

const service = new request(config, interceptRequest, interceptResponse)

//我的卡片-分类
const getCouponCardCatalog = (data) => {
  return service.get(apiUrl.getCouponCardCatalog, data)
}

const getCouponCardList = (data) => {
  return service.get(apiUrl.getCouponCardList, data)
}

const getCouponCardInfo = (data) => {
  return service.get(apiUrl.getCouponCardInfo, data)
}

const doSendCouponCard = (data) => {
  return service.get(apiUrl.doSendCouponCard, data)
}

//卡包领取页面
const getCouponOrderInfo = (data) => {
  return service.get(apiUrl.getCouponOrderInfo, data)
}

const getCouponCardUseView = (data) => {
  return service.post(apiUrl.getCouponCardUseView, data)
}

const createCouponGetOrder = (data) => {
  return service.post(apiUrl.createCouponGetOrder, data)
}

const getGoodsInfo = (data) => {
  return service.get(apiUrl.getGoodsInfo, data)
}

const getCouponCardCanBuyList = (data) => {
  return service.get(apiUrl.getCouponCardCanBuyList, data)
}

const couponCardInfo = (data) => {
  return service.get(apiUrl.couponCardInfo, data)
}

const couponCardBuyConfirm = (data) => {
  return service.get(apiUrl.couponCardBuyConfirm, data)
}

const couponCardBuy = (data) => {
  return service.get(apiUrl.couponCardBuy, data)
}

const getSwiperList = (data) => {
  return service.get(apiUrl.getSwiperList, data)
}

const getRefundInfo = (data) => {
  return service.get(apiUrl.getRefundInfo, data)
}

const getCardUseLog = (data) => {
  return service.get(apiUrl.getCardUseLog, data)
}

const getOrderExpressInfo = (data) => {
  return service.get(apiUrl.getOrderExpressInfo, data)
}

const getRefundReasonList = (data) => {
  return service.get(apiUrl.getRefundReasonList, data)
}

const handleRefundApply = (data) => {
  return service.post(apiUrl.handleRefundApply, data)
}

const gitRule = (data) => {
  return service.get(apiUrl.gitRule, data)
}

const cardRecommendList = (data) => {
  return service.get(apiUrl.cardRecommendList,data)
}

export default {
  getCouponCardCatalog,
  getCouponCardList,
  getCouponCardInfo,
  doSendCouponCard,
  getCouponOrderInfo,
  getCouponCardUseView,
  createCouponGetOrder,
  getGoodsInfo,
  getCouponCardCanBuyList,
  couponCardInfo,
  couponCardBuyConfirm,
  couponCardBuy,
  getSwiperList,
  getRefundInfo,
  getCardUseLog,
  getOrderExpressInfo,
  getRefundReasonList,
  handleRefundApply,
  gitRule,
  cardRecommendList
}