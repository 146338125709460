import appSetting from '../../appSetting'
/**
 * 环境配置
 */
const env = appSetting.isDevelopment  ? 'dev' : 'prod'
/**
 * 不同环境的api
 */
const EnvConfig = {
    dev: {
        baseApi: '/api',
        mockApi: '/mock'
    },
    prod: {
        baseApi: 'http://api.rsck.group/',
        mockApi: '/mock'
    }
}
/**
 * api地址
 */
const apiUrl = {
    //微信sdk相关接口
    jsweixin: {
        getConfig: 'wx-config', //初始化配置
        login: 'wx-login', //登陆
        getUserinfo: 'wx-userinfo', //获取用户信息
    },
}
/**
 * api接口规则
 */
const rules = {}

export default {
    mock: false,
    env,
    apiUrl,
    rules,
    ...EnvConfig[env],
}