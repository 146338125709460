const contract = [{
    path: 'home',
    name: 'Home',
    component: () => import('@/pages/community/home.vue')
}, {
    path: 'category',
    name: 'Category',
    component: () => import('@/pages/community/category.vue')
},
    {
        path: 'today-category',
        name: 'TodayCategory',
        component: () => import('@/pages/community/today-category.vue')
    },
    {
        path: 'order-info',
        name: 'OrderInfo',
        component: () => import('@/pages/community/orderInfo.vue')
    },
    {
        path: 'order-view',
        name: 'OrderInfo',
        component: () => import('@/pages/community/order-view.vue')
    },
    {
        path: 'order-confrim',
        name: 'OrderConfrim',
        component: () => import('@/pages/community/orderConfrim.vue')
    },
    {
        path: 'apply-for-refund',
        name: 'ApplyForRefund',
        component: () => import('@/pages/community/applyForRefund.vue')
    },
    {
        path: 'search',
        name: 'Search',
        component: () => import('@V/community/search.vue')
    }, {
        path: 'search-list',
        name: 'SearchList',
        component: () => import('@V/community/searchList.vue')
    },
    {
        path: 'cart',
        name: 'Cart',
        component: () => import('@V/community/cart.vue')
    },
    {
        path: 'goods-detail',
        name: 'GoodsDetail',
        component: () => import('@V/community/goodsDetail.vue')
    },
    {
        path: 'map',
        name: 'Map',
        component: () => import('@V/community/map.vue')
    },
    {
        path: 'direct-sales',
        name: 'DirectSales',
        component: () => import('@V/community/direct-sales.vue')
    },
    {
        path: 'join-goods',
        name: 'JoinGoods',
        component: () => import('@V/community/join-goods.vue')
    },
    {
        path: 'join-order-confirm',
        name: 'JoinOrderConfirm',
        component: () => import('@V/community/join-order-confirm.vue')
    },
    {
        path: 'join-order-info',
        name: 'JoinOrderInfo',
        component: () => import('@V/community/join-order-info.vue')
    }
]

export default contract;