import appSetting from '../../appSetting'

/**
 * 环境配置
 */
const env = appSetting.isDevelopment ? 'dev' : 'prod'

/**
 * 不同环境的api
 */
const EnvConfig = {
    dev: {
        baseApi: 'http://community-mall.rsck.group/',
        // baseApi: 'https://community-mall.mengjingloulan.com/', //线上服务器地址
        mockApi: '/mock'
    },
    prod: {
        // baseApi: 'http://api.rsck.group/',//测试服务器地址
        baseApi: 'https://community-mall.mengjingloulan.com/', //线上服务器地址
        // baseApi: 'http://community-mall.rsck.group/',
        mockApi: '/mock'
    },


}
/**
 * api地址
 */
const apiUrl = {
    //首页相关接口
    home: {
        getSwiperList: 'flash', //获取轮播图
        getMenuList: 'catalog', //获取menu按钮列表
        getGoodsCatalog: 'goods-catalog', //获取menu按钮列表
        getHotGoodsList: 'hot-goods', //获取首页热门商品列表
        groupBuyingToday: 'group-buying-today', //获取首页热门商品列表
        groupBuyingCurrent: 'group-buying-current', //获取首页热门商品列表
        goodsLabels: 'goods-labels', //获取首页商品列表标签

    },
    //搜索相关接口
    search: {
        getHotSearch: 'get_keyword', //获取热门搜索列表
        search: 'search-goods', //根据传入的字段搜索商品
    },
    //分类页面相关接口
    category: {
        getTypeList: 'catalog-list', //获取热门搜索列表
        getGoodsList: 'catelog-goods-list', //根据传入的字段搜索商品
        getGoodsListByGoodType: 'goods-type-catelog-goods-list', //根据传入的字段搜索商品
        OriginDirectSelling: 'origin-direct-selling', //获取首页热门商品列表
    },
    //商品相关接口
    goods: {
        getDetail: 'goods-info', //获取商品详情
        followAdd: 'add-favorite-single', //添加关注
        followAdds: 'add-favorite', //添加多个关注商品
        followDels: 'remove-favorite', //删除多个关注商品
    },
    //购物车相关接口
    cart: {
        getList: 'cart-list', //获取购物车列表
        add: 'cart', //添加到购物车
        del: 'delete-cart', //删除购物车列表
        updata: 'update-cart', //更新购物车数据
    },
    //订单相关接口
    order: {
        previewByCart: 'order-preview', //从购物车创建订单预览
        createByCart: 'create-order', //从购物车创建订单
        previewBySingle: 'order-preview-quick', //单个商品订单预览
        createBySingle: 'create-order-quick', //单个商品订单创建
        getInfo: 'order-info', //获取订单详情
        refund: 'refund', //获取申请退款详情
        refundApply: 'refund-apply', //提交申请退款
        getMapList: 'order-point-list', //获取自提点列表
        getExpressList: 'mall-express-goods', //通过订单和商品获取快递信息
        getOrderViewInfo: 'order-sn-info',
        receiveOrderGoods: 'community-order-receive',//社区商城快递商品确认收货
    },
    //配送员接口
    courier: {
        getUserInfo: 'delivery-my-center', //配送员个人中心
        getNoticeList: 'delivery-pick-notice', //取货通知
        getNoticeOrderList: 'delivery-pick-notice-list', //取货通知点击进去的列表
        getSendList: 'delivery-point-notice', //配送通知列表
        geSendNoticeList: 'delivery-point-notice-list', //配送通知点击进去的列表
        geDoneOrderList: 'delivery-history-list', //配送员的配送成功订单列表
        geDoneOrderInfo: 'delivery-history-info', //配送员的配送成功订单详情
        geScanInfo: 'delivery-scan-info', //配送员的配送成功订单详情
        makeSure: 'delivery-make-sure', //配送员扫码确认收货
        makeFlowCode: 'delivery-make-flow-code', //配送员批量生产流程码
        confirmGetGoods: 'delivery-confirm-get-goods',//配送员确认收货
        getSendReceiveCode: 'delivery-get-send-receive-code',//配送员获取配送订单确认二维码
        getBackGoodsList: 'delivery-get-back-goods',//配送员获取需要取回的商品列表
        deliveryCode: 'delivery-code',//读取配送员的身份码
        deliveryAfterCode: 'delivery-after-code',//读取配送员需要取货的售后商品二维码
        getSendReceiveInfo: 'delivery-get-send-receive-info',//配送员扫描自提点的收货码后获取信息详情
        confirmSitePointReceiveGoods: 'delivery-confirm-send-goods',//配送员扫描自提点的收货码后进行确认收货
        CanWithDraw: 'delivery-can-withdraw',//获取配送员可以提现的金额
        doWithDraw: 'delivery-withdraw',//配送员申请提现,
        doWithDrawLog: 'delivery-withdraw-log',//配送员提现记录
        confirmSendToSitePoints: 'delivery-confirm-send-to-site-points',//配送员确认已经送到自提点
        getDistributionOrderList: 'delivery-distribution-orders',//统一配送订单接口
        confirmDistributionOrder: 'confirm-distribution-send-over',//配送员对统一配送订单设置确认送达状态
    },
    //自提点相关的接口
    points: {
        myCenterInfo: 'point-my-center', //个人中心信息
        pointOnWay: 'point-on-way', //站点待提货-在路上列表
        pointUnFinishOrder: 'point-un-finish-order', //站点未完成列表
        pointFinishOrder: 'point-finish-order', //站点已经完成列表
        pointOverOrder: 'point-over-order', //站点逾期列表
        pointWillSendOrder: 'point-will-send-order',//即将发送到自提点的订单列表
        pointSerarchCode: 'point-serarch-code', //通过手机和提过码 获取记录
        pointOrderInfo: 'point-order-info', //自提点方获取订单详情
        pointOrderList: 'point-order-list', //自提点方获取订单列表
        makeSureSend: 'point-make-send', //自提点方获取订单详情
        makeSureReceive: 'point-make-receive', //自提点签收货物
        makeSurePlReceive: 'point-pl-make-receive', //自提点批量签收货物
        CanWithDraw: 'point-can-with-draw', //可提现金额数据
        doWithDraw: 'point-do-with-draw', //提现
        doWithDrawLog: 'point-do-with-draw-log', //提现记录
        getStoresStatistics: 'statistics/get-stock-up-nums', //自提点批量签收货物
        getPointStockUpNums: 'statistics/get-point-stock-up-nums', //自提点批量签收货物
        platformProfitByPoint: 'statistics/platform-profit-by-point', //自提点批量签收货物
        platformProfitByGoods: 'statistics/platform-profit-by-goods', //自提点批量签收货物
        statisticsPointsDownload: 'statistics/statistics-points-download', //自提点批量签收货物
        getStatisticsAuth: 'statistics/get-statistics-auth', //自提点批量签收货物
        getSendReceiveInfo: 'point-get-send-receive-info', //自提点扫描配送员的送货码后获取信息详情
        confirmReceiveGoods: 'point-confirm-receive-goods',//自提点确认收货
        pointSearchUser: 'point-search-users',//自提点管理员搜索会员
        pointSetWorker: 'point-set-worker',//自提点管理员设置员工
        pointWorkerList: 'point-worker-list',//自提点员工列表
        pointRemoveWorker: 'point-remove-worker',//自提点删除员工
        pointWillReceiveGoods: 'point-will-receive-goods',//自提点即将收货的商品
        getPointSendReceiveCode: 'point-receive-code',//获取即将收货的提货码

    },
    //商铺相关的接口
    //商铺相关的接口
    stores: {
        orderCatalog: '/stores-manage/order-catalog', //获取分类列表
        mallOrder: '/stores-manage/mall-order', //获取分类列表
        mallOrderInfo: '/stores-manage/stock_order_info', //商户订单详情
        expressList: '/express-list',//获取快递列表
        setOrderExpress:'/set-order-express',//设置一件代发订单发货的快递信息
    },
    users: {
        userMoney: '/users/money'
    },
    //商家联盟
    storeJoins: {
        goodsList: 'store-join-goods',//商家联盟商品列表
        orderView: 'store-join-goods/order-view',//商家联盟商品详情
        createStoreJoinOrder: 'store-join-goods/create-order',//创建商家联盟礼包订单
        getStoreJoinOrderInfo: 'store-join-goods/order-info',//商家联盟礼包订单详情
        getJoinGoodsUseCode: 'store-join-goods/verify-code',//获取联盟礼包商品的核销码
    }

}
/**
 * api接口规则
 */
const rules = {}

// module.exports = {
//
// }

export default {
    mock: false,
    env,
    apiUrl,
    rules,
    ...EnvConfig[env],
}
