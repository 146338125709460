export default {
    default: (to, from, next) => {
        console.log('这是contract的路由拦截');
        next();
    },
    children: {
        New: {
            default: (to, from, next) => {
                next();
            },
            children: {}
        },
        Renew: {
            default: (to, from, next) => {
                next();
            },
            children: {}
        }
    }
}