import common from '@A/common/index.js'
import contract from '@A/contract/index.js'
import community from '@A/community/index.js'
import couponCard from '@A/couponCard/index.js'
import services from '@A/services/index.js'
import actives from '@A/actives/index.js'

const install = (Vue) => {
  Vue.prototype.$api = {
    common,
    contract,
    community,
    couponCard,
    services,
    actives
  }
}

export default {
  install
}