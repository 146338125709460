import Cookies from 'js-cookie'
import $community from '@A/community/index.js'
import { updataStore } from '@/store/common.js'

let lifeData = {}

try {
  // 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
  lifeData = Cookies.get('lifeData_community_order')
  lifeData = JSON.parse(lifeData)
} catch (e) {
  //
}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = []
export default {
  namespaced: true,
  state: {
    isBuyRSQ: false, //是否同时购买人生券默认false
    //购物车
    cart_ids: [], //购物车id
    type: 1, //1 自提 2快递 默认自提
    //单品
    remark: '', //备注
    goods_id: 0, //商品id
    specs: '', //规格
    number: 0, //购买数量
    //商品列表数据
    goods: [
      // {
      //   cart_id: 0, //购物车ID，如果需要在此页面更新购物车，使用此值
      //   goods_id: 3088, //商品ID
      //   spec_title: "默认规格", //规格格式化后的文字
      //   spec_ids: "", //规格ID数组
      //   number: 1, //购买数量
      //   cover:
      //     "http://api.rsck.group/storage/uploads/20210824/5f55398b7baed67c01eba65348c1e43d.jpeg", //封面图片
      //   cannot_return_reason: "", //不能退换货的说明，如果此值接口返回为空，则不显示提示文字
      //   title: "这是在测试", //商品名称
      //   product_price: "105.00", //单个商品原价
      //   market_price: "100.00", //单个商品售价
      //   total_product_price: 105, //总计的商品原价
      //   total_market_price: 100, //总计的商品售价
      //   deduct_text: "可用人生券抵扣10元", //人生券抵扣的文字提示，如果此值为空，则不进行显示
      // },
    ],
    //订单明细
    order_info: {
      express_type: 0, //配送类型 1:自提点配送 2:快递配送
      allow_express_type: [1], //是个是否可以切换配送类型的数组，如果只包含一个值，则不能切换配送类型，如果包含2个值，则允许切换配送类型，其中0代表快递配送 1代表自提点配送
      goods_amount: 0, //订单价格
      can_use_renshengquan: 0, //可以使用的人生券数量
      real_can_use_renshengquan: 0, //未勾选购买人生券 可以使用的人生券数量
      buy_renshengquan_pay_money: 0, //购买人生券需要支付的金额
      shipping_fee: 0, //运费
      pay_amount: 0, //会员需要支付的金额
      real_pay_amount: 0, //未勾选购买人生券 会员需要支付的金额
    },
    //勾选购买人生券优惠明细信息
    save_info: {
      total_product_price: 0, //商品总市场价格
      total_market_price: 0, //商品总售价
      save_money: 0, //立减的金额（商品总市场价格 - 商品总售价）
      can_use_renshengquan: 0, //可以使用的人生券数量
      total_save_money: 0, //总优惠金额
    },
    //未勾选购买人生券优惠明细信息
    real_save_info: {
      total_product_price: 0, //商品总市场价格
      total_market_price: 0, //商品总售价
      save_money: 0, //立减的金额（商品总市场价格 - 商品总售价）
      can_use_renshengquan: 0, //可以使用的人生券数量
      total_save_money: 0, //总优惠金额
    },
    //如果需要购买人生券，显示的信息，如果此值返回的是空对象，则不显示购买人生券的信息
    //人生券足够 数组为空
    renshengquan_info: {
      need_buy: 1, //需要购买
      warning_text: '', //购买人生券的提示文字
      buy_number: 0, //需要购买的人生券张数
      title: '', //购买的人生券标题
      need_money: 0, //购买这些人生券需要的总价格
      will_use: 0, //即将使用的人生券数量
      will_buy: 0, //即将购买的人生券总数量
    },
    //配送信息
    express: {
      //当订单是自提点订单预览时，会有此字段
      site_point: {
        real_name: '', //提货人名称
        mobile: '', //提货人手机号码
        site_point_id: 0, //自提点ID
        site_name: '', //自提点名称
        address: '', //自提点详细地址
      },
      //当订单是快递订单时，包含用户的收获地址信息
      express: {
        address_id: '', //地址id
        province: '', //省份
        city: '', //城市
        town: '', //城镇
        address: '', //详细地址
        real_name: '', //真是姓名
        mobile: '', //手机号
      },
    },
    //可用优惠券
    couponList: [],
    //选择的优惠券ID
    useCouponId: 0,
    //优惠券选择的自提点
    couponSitePoint: {
      site_point_id: 0,
      site_name: '',
      address: '',
      real_name: '',
      mobile: ''
    },
    //优惠券商品
    couponGoods: [],
    //是否选择地址
    choose_address: 1
  },
  mutations: {
    updataStore (state, payload) {
      // console.log('community_order payload', payload)
      updataStore('community_order', saveStateKeys, state, payload)
    }
  },
  getters: {},
  actions: {
    async reset ({
                   commit,
                   state
                 }) {
      console.log('reset', state)
      await commit('updataStore', {
        name: 'goods_id',
        value: 0
      })
      await commit('updataStore', {
        name: 'specs',
        value: ''
      })
      await commit('updataStore', {
        name: 'number',
        value: 0
      })
      await commit('updataStore', {
        name: 'remark',
        value: ''
      })
      await commit('updataStore', {
        name: 'cart_ids',
        value: []
      })
      await commit('updataStore', {
        name: 'type',
        value: 0
      }),
        await commit('updataStore', {
          name: 'coupon_list',
          value: []
        })

    },
    //初始化信息
    init ({
            state,
            commit,
            dispatch
          }, params) {
      return new Promise((resolve, reject) => {
        if (params?.goods_id > 0) {
          let specs = ''
          if (typeof params?.specs == 'string') {
            if (params.specs == '') {
              specs = ''
            } else {
              if (params.specs.indexOf('{') >= 0) {
                specs = Object.values(JSON.parse(params.specs))
                  .sort((a, b) => a - b)
                  .join(',')
              } else {
                specs = params.specs
              }
            }
          } else {
            specs = params.specs
          }
          if (params.goods_id == state.goods_id && specs == state.specs) {
            return
          }
          commit('updataStore', {
            name: 'goods_id',
            value: params.goods_id
          })
          commit('updataStore', {
            name: 'specs',
            value: specs
          })

          commit('updataStore', {
            name: 'number',
            value: params.num
          })

          dispatch('previewSingleGoods').then(() => {
            resolve()
          })

        }
        if (params?.cart_ids?.length > 0) {
          //购物车
          let type = params?.type
          let cart_ids = ''
          console.log(typeof params?.cart_ids)
          if (typeof params?.cart_ids == 'string') {
            cart_ids = JSON.parse(decodeURI(params?.cart_ids))
            if (cart_ids.join(',') == state.cart_ids.join(',') && type == state.type) {
              return
            }
          } else {
            cart_ids = params.cart_ids
          }
          commit('updataStore', {
            name: 'cart_ids',
            value: cart_ids
          })
          commit('updataStore', {
            name: 'type',
            value: type
          })
          dispatch('previewCartGoods').then(() => {
            resolve()
          })

        }
        if (params.paySign) {
          commit('updataStore', {
            name: 'remark',
            value: params.remark
          })
          commit('updataStore', {
            name: 'express',
            value: params.express
          })
          resolve()
        }
      })

    },
    //单商品订单预览创建
    previewSingleGoods ({
                          state,
                          commit
                        }) {
      return new Promise((resolve, reject) => {
        //预览单个商品订单确认
        let params = {
          goods_id: state.goods_id, //商品ID
          spec_ids: state.specs, //商品规格，多个规格用","连接
          number: state.number, //购买数量
          location: '39.98246, 117.07822', //用户的地理位置经纬度，如：39.98246, 117.07822
          // is_buy: 1, //下单时是否同时购买人生券 0：不是 1：是 默认为0
        }
        try {
          $community.order.previewBySingle(params).then(query => {
            let keys = ['goods', 'order_info', 'save_info', 'real_save_info', 'renshengquan_info', 'express','choose_address']
            keys.forEach((key) => {
              commit('updataStore', {
                name: key,
                value: query.data[key]
              })
            })

            commit('updataStore', {
              name: 'couponList',
              value: query?.data?.coupon_list || []
            })

            resolve()
          })
        } catch (error) {
          //订单预览创建失败
          reject(error)
        }
      })
    },
    //单商品订单预览创建
    changeGoodsNum ({
                      state,
                      commit
                    }) {

      return new Promise((resolve, reject) => {
        try {
          //预览单个商品订单确认
          let params = {
            goods_id: state.goods_id, //商品ID
            spec_ids: state.specs, //商品规格，多个规格用","连接
            number: state.number, //购买数量
            location: '39.98246, 117.07822', //用户的地理位置经纬度，如：39.98246, 117.07822
            // is_buy: 1, //下单时是否同时购买人生券 0：不是 1：是 默认为0
          }
          $community.order.previewBySingle(params).then(res => {
            let query = res
            let keys = ['goods', 'order_info', 'save_info', 'real_save_info', 'renshengquan_info', 'express']
            keys.forEach((key) => {
              if (key == 'express') {
                //如果需要更新快递方式时，需要判断要更新的数据与现在的数据是否一致，并且要更新的数据是否时有效的
                if (state.express.site_point.site_point_id == 0 && query.data[key].site_point.site_point_id == state.express.site_point.site_point_id == 0) {
                  commit('updataStore', {
                    name: key,
                    value: query.data[key]
                  })
                }
              } else {
                commit('updataStore', {
                  name: key,
                  value: query.data[key]
                })
              }
            })

            commit('updataStore', {
              name: 'couponList',
              value: query?.data?.coupon_list || []
            })

            resolve(query.data)
          })
        } catch (error) {
          //订单预览创建失败
          reject(error)
        }
      })
    },

    //购物车预览订单创建
    previewCartGoods ({
                        state,
                        commit
                      }) {
      //购物车商品预览
      return new Promise((resolve, reject) => {
        try {
          let params = {
            cart_ids: state.cart_ids, //
            type: state.type, //1 自提 2快递
            location: '39.98246, 117.07822', //经纬度  维度,经度
            // is_buy: 1, //下单时是否购买人生券
          }
          $community.order.previewByCart(params).then(query => {
            let keys = ['goods', 'order_info', 'save_info', 'real_save_info', 'renshengquan_info', 'express']
            keys.forEach((key) => {
              commit('updataStore', {
                name: key,
                value: query.data[key]
              })
            })

            commit('updataStore', {
              name: 'couponList',
              value: query?.data?.coupon_list || []
            })

            resolve()
          })

        } catch (error) {
          //购物车预览创建失败
          reject(error)
        }
      })
    },
    //设置自提点
    setPointId ({
                  state,
                  commit
                }, params) {
      let item = {
        address: params.address,
        site_name: params.site_name,
        site_point_id: params.site_point_id,
        mobile: state.express.site_point.mobile,
        real_name: state.express.site_point.real_name,
      }

      let express = JSON.parse(JSON.stringify(state.express))
      express.site_point = item
      commit('updataStore', {
        name: 'express',
        value: express
      })
    },

    siteCouponSitePoint ({state, commit}, params) {
      let info = JSON.parse(JSON.stringify(state.couponSitePoint))
      let couponSitePoint = {
        site_point_id: params?.site_point_id || 0,
        site_name: params?.site_name || '',
        address: params?.address || '',
        real_name: info?.real_name || '',
        mobile: info?.mobile || '',
      }

      commit('updataStore', {
        name: 'couponSitePoint',
        value: couponSitePoint
      })
    },

  },

}