import Cookies from 'js-cookie'
import { updataStore } from '@/store/common.js'

let lifeData = {}

try {
  // 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
  lifeData = Cookies.get('lifeData_coupon_card_card')
  lifeData = JSON.parse(lifeData)
} catch (e) {
  //
}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = []
export default {
  namespaced: true,
  state: {
    cardId: 0,
    goodsId: [],
    //配送信息
    express: {
      //当订单是自提点订单预览时，会有此字段
      site_point: {
        real_name: '', //提货人名称
        mobile: '', //提货人手机号码
        site_point_id: 0, //自提点ID
        site_name: '', //自提点名称
        address: '', //自提点详细地址
      },
      //当订单是快递订单时，包含用户的收获地址信息
      express: {
        address_id: '', //地址id
        province: '', //省份
        city: '', //城市
        town: '', //城镇
        address: '', //详细地址
        real_name: '', //真是姓名
        mobile: '', //手机号
      },
    },
  },
  mutations: {
    updataStore (state, payload) {
      updataStore('coupon_card_card', saveStateKeys, state, payload)
    }
  },
  getters: {},
  actions: {
    init ({state, commit, dispatch}, params) {
      if (params.card_id > 0) {
        commit('updataStore', {
          name: 'cardId',
          value: params.card_id
        })
      }

      let goods_id = params.goods_id
      let goodsIdArray = goods_id.split('|')
      let finalGoodsId = []
      goodsIdArray.forEach(item => {
        let itemArray = item.split('_')
        finalGoodsId.push({goods_id: itemArray[0], type: itemArray[1]})
      })

      commit('updataStore', {
        name: 'goodsId',
        value: finalGoodsId
      })

      if (typeof params.express == 'object') {
        let expressData = params.express.express
        let express = {
          address: expressData.address,
          address_id: expressData.address_id,
          city: expressData.city,
          mobile: expressData.mobile,
          province: expressData.province,
          real_name: expressData.real_name,
          town: expressData.town,
        }
        commit('updataStore', {
          name: 'express.express',
          value: express
        })
      }

    },
    setExpressPointInfo ({
                           state,
                           commit
                         }, params) {
      // let item = {
      //   address: params.address,
      //   site_name: params.site_name,
      //   site_point_id: params.site_point_id,
      //   mobile: state.express.site_point.mobile,
      //   real_name: state.express.site_point.real_name,
      // }
      // var express = JSON.parse(JSON.stringify(state.express))
      // console.log('设置前的值',express)
      // console.log('设置的值',item)
      let express = {
        address: params.address,
        site_name: params.site_name,
        site_point_id: params.site_point_id,
        mobile: state.express.site_point.mobile,
        real_name: state.express.site_point.real_name,
      }

      console.log('设置后的值',express)
      commit('updataStore', {
        name: 'express.site_point',
        value: express
      })
    }
  },

}