<template>
    <div class="empty-container">
        <div class="empty">
            <div class="empty-img">
                <img :src="require('@/static/image/icons/empty.png')"/>
            </div>
            <div class="title">
                {{title}}
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'emptyV1',
    props: {
      title: {
        type: String,
        default: '暂无相关数据'
      }
    }
  }
</script>

<style scoped lang="scss">
    .empty-container {
        width: 100%;
        display: flex;

        .empty {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .empty-img {
                width: 100%;
                height: 250px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                margin-bottom: 20px;

                img {
                    width: 100px;
                    height: 100px;
                }

            }

            .title {
                height: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #939393;
                font-size: 12px;
            }
        }
    }
</style>