import Cookies from 'js-cookie';
import {
	updataStore
} from '@/store/common.js';
let lifeData = {};

try {
	// 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
	lifeData = Cookies.get('lifeData_common_platform');
} catch (e) {
	//捕获异常
}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = ['host', 'header', 'params'];
export default {
	namespaced: true,
	state: {
		host: null,
		header: {
			'city-id': 0,
			'platform': 'null',
			'partner-id': 0,
			'store-id': 0,
			'store-token': 0,
			'Token': '',
			'unionid': null,
			'wx-version': null,
			'user-id': 0,
		},
		params: null
	},
	mutations: {
		updataStore(state, payload) {
			updataStore('common_platform', saveStateKeys, state, payload);
		}
	},
	actions: {

	},
	getters: {}
}