//引入mockjs
import Mock from 'mockjs';
// 引入函数类
import contract from '@/mock/modules/contract.js';
import community from '@/mock/modules/community.js';

//获取mock函数

const {
    mock
} = Mock;

//设置延时，模拟真实请求时间
Mock.setup({
    timeout: 400
});

//合同相关的接口
Object.keys(contract).forEach((key) => {
    let rex = `/mock/contract/${key}`;
    mock(RegExp(rex + ".*"), contract[key].type, contract[key].func);
})
//社区相关的接口
Object.keys(community).forEach((key) => {
    let rex = `/mock/${key}`;
    mock(RegExp(rex + ".*"), community[key].type, community[key].func);
})