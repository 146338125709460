const parseUrl={
    getUrlParams(){
        let params=location.href.split('params=')[1];
        if(!params){
            return
        }
        params=decodeURIComponent(params);
        params=JSON.parse(params);
        return params
    },
    //获取参数数据
    getDataByParams(){
        let params=location.href.split('params=')[1];
        if(!params){
            return
        }
        params=decodeURIComponent(params);
        params=JSON.parse(params);
        return params?.data
    },
    //获取header
    getHeaderByParams(){
        let params=location.href.split('params=')[1];
        if(!params){
            return
        }
        params=decodeURIComponent(params);
        params=JSON.parse(params);
        return params?.header
    },
    //获取ip和域名
    getHost(){
        return {
            hostname:location.hostname,
            hostport:location.port
        }
    }
}
export default parseUrl;