import Vue from 'vue';
import codeV1 from './codeV1.vue'
let newInstance = null;
const codeClass = Vue.extend(codeV1); //创建构造函数
const initInstance = () => { // 执行方法后完成挂载
    newInstance = new codeClass(); // 实例化
    // 实例化后手动挂载，得到$el真实Dom，将其添加到body最后
    document.body.appendChild(newInstance.$mount().$el);
}
export default (showCodeV1) => {
    //导出一个方法， 接受配置参数
    if (!newInstance) {
        initInstance(); // 挂载
    }
    newInstance.show = true;
    if (showCodeV1) {
        Object.assign(newInstance, showCodeV1);
    }
}