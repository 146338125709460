const files = require.context('@/store/modules', true, /\.js$/, 'sync');

let vuex_modules = {};
files.keys().forEach((key) => {
    if (key !== './index.js') {
        let filesName = key.replace(/^\.\/|\.js$/gi, '').replace(/\//gi, '_');
        vuex_modules[filesName] = files(key).default;
    }
});

export default vuex_modules;