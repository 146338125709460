import Cookies from 'js-cookie'
import {
  updataStore
} from '@/store/common.js'

let lifeData = {}

try {
  // 尝试获取本地是否存在lifeData变量，第一次启动时是不存在的
  lifeData = Cookies.get('lifeData_community_history')
  lifeData = JSON.parse(lifeData)
} catch (e) {
  //
}

// 需要永久存储，且下次启动需要取出的，在state中的变量名
let saveStateKeys = ['history']


export default {
  namespaced: true,
  state: {
    history: lifeData ? lifeData.history : [],
  },
  mutations: {
    updataStore(state, payload) {
      updataStore('community_history', saveStateKeys, state, payload)
    }
  },
  actions: {
    async addHistory({
      state,
      commit,
    }, payload) {
      payload = window.$utils.string.trim(String(payload))
      let historyList = window.$utils.deepClone(state.history)
      if (payload != '' && historyList.indexOf(payload) == -1) {
        historyList.push(payload)
        commit('updataStore', {
          name: 'history',
          value: historyList
        })
      }

    },
  },
  getters: {}
}