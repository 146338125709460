/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
 function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
 function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}
/**
 * 时间戳转当前日期
 * @param {Object} distance 
 */
 function getDistanceTime(distance) {
  var day = 0
  var hour = 0
  var minute = 0
  var second = 0

  if (distance > 0) {
    day = Math.floor(distance / 60 / 60 / 24)
    hour = Math.floor(distance / 60 / 60 % 24)
    minute = Math.floor(distance / 60 % 60)
    second = Math.floor(distance % 60)
  }
  return day + '天' + hour + '时' + minute + '分' + second + '秒'
}

/**
 * 日期转时间戳
 * @param {string} dateStr
 * @returns
 */
 function parseDateToTimestamp(dateStr) {
  var date = new Date(dateStr)
  console.log(date)
  var time = Date.parse(date) / 1000
  return time
}
/**
 * 时间戳秒转天数
 * @param {String} seconds 时间戳 秒
 */
 function parseSeconds(seconds) {
  if (seconds > 0) {
    return Math.ceil(seconds / 60 / 60 / 24)
  }
  return 0
}
/**
 * 距离当前时间还有多长时间
 * @param {Object} time	时间戳
 */
 function dateDifference(time) {
  var timestamp = Date.parse(new Date())
  timestamp = timestamp / 1000
  var expire_time = parseInt(Date.parse(parseTime(time, '{y}-{m}-{d} {h}:{i}')) / 1000)// 过期时间
  return expire_time - timestamp
}
/**
 * 天转秒
 * @param {Object} day
 */
 function parseDay(day) {
  if (day > 0) {
    return day * 24 * 60 * 60
  }
  return 0
}
/**
 * 将时间从unx转成指定的时间格式 （yyyy-MM-dd-HH-mm-ss）
 * @param {*} unx //传入unx时间戳
 * @param {*} fmt //传入格式
 */
 function unxToDate(unx, fmt) {
  unx = new Date(unx * 1000)
  var o = {
    'M+': unx.getMonth() + 1, // 月份
    'd+': unx.getDate(), // 日
    'H+': unx.getHours(), // 小时
    'm+': unx.getMinutes(), // 分
    's+': unx.getSeconds(), // 秒
    'q+': Math.floor((unx.getMonth() + 3) / 3), // 季度
    'S': unx.getMilliseconds() // 毫秒
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (unx.getFullYear() + '').substr(4 - RegExp.$1.length))
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

export default {
	parseTime,
	formatTime,
	getDistanceTime,
	parseDateToTimestamp,
	parseSeconds,
	dateDifference,
	parseDay,
	unxToDate
}