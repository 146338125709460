import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from '@/router/beforeEach'
import routes from '@/router/routes/Root';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach(beforeEach);
export default router