
/**
 * @param {String} str 要出去空白的字符串  
 * @param {String} type 类型 默认去除所有的空白  
 * @example trim("aaa aa aa ")
 */
function trim(str, type = 'all') {
	if (type == 'all') {
		return str.replace(/\s+/g, "");
	} 
	if (type == "left") {
		return str.replace(/^\s*/, '');
	}
	if (type == 'right') {
		return str.replace(/(\s*$)/g, "");
	}
	if (type == 'between') {
		return str.replace(/^\s+|\s+$/g, "");
	}
}
export default {
	trim
}