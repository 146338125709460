import { render, staticRenderFns } from "./codeV1.vue?vue&type=template&id=c6b0096e&scoped=true&"
import script from "./codeV1.vue?vue&type=script&lang=js&"
export * from "./codeV1.vue?vue&type=script&lang=js&"
import style0 from "./codeV1.vue?vue&type=style&index=0&id=c6b0096e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b0096e",
  null
  
)

export default component.exports